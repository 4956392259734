.woocommerce-checkout .checkout .col-2 h3#ship-to-different-address {
    float: left;
    clear: none;
}
.woocommerce-checkout .checkout .col-2 .form-row-first,
.woocommerce-checkout .checkout .col-2 .notes { clear: left }
.woocommerce-checkout .checkout .create-account small {
    font-size: 11px;
    color: clr-gray;
    font-weight: 400;
}
.woocommerce-checkout .checkout div.shipping-address {
    padding: 0;
    clear: left;
    width: 100%;
}
.single-product .twentythirteen p.stars,
.woocommerce-checkout #payment ul.payment_methods:after,
.woocommerce-checkout .checkout .shipping_address { clear: both }
.woocommerce-checkout #payment {
    background: clr-light-gray;
    border-radius: 5px;
}
.woocommerce-checkout #payment ul.payment_methods {
    text-align: left;
    padding: 1em;
    border-bottom: 1px solid #d3ced2;
    margin: 0;
    list-style: none;
}
.woocommerce-checkout #payment ul.payment_methods:after,
.woocommerce-checkout #payment ul.payment_methods:before {
    content: " ";
    display: table;
}
.woocommerce-checkout #payment ul.payment_methods li {
    line-height: 2;
    text-align: left;
    margin: 0;
    font-weight: 400;
}
.woocommerce-checkout #payment ul.payment_methods li input { margin: 0 1em 0 0 }
.woocommerce-checkout #payment ul.payment_methods li img {
    vertical-align: middle;
    margin: -2px 0 0 .5em;
    padding: 0;
    position: relative;
    box-shadow: none;
}
.woocommerce-checkout #payment ul.payment_methods li img+img { margin-left: 2px }
.woocommerce-checkout #payment div.form-row { padding: 1em }
.woocommerce-checkout #payment div.payment_box {
    position: relative;
    width: 96%;
    padding: 1em 2%;
    margin: 1em 0;
    font-size: .92em;
    border-radius: 2px;
    line-height: 1.5;
    background-color: clr-light-gray;
    color: clr-gray;
}
.woocommerce-checkout #payment div.payment_box input.input-text,
.woocommerce-checkout #payment div.payment_box textarea { border-color: clr-light-gray }
.woocommerce-checkout #payment div.payment_box ::-webkit-input-placeholder { color: clr-light-gray }
.woocommerce-checkout #payment div.payment_box :-moz-placeholder { color: clr-light-gray }
.woocommerce-checkout #payment div.payment_box :-ms-input-placeholder { color: clr-light-gray }
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number {
    font-size: 1.5em;
    padding: 8px;
    background-repeat: no-repeat;
    background-position: right;
}
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.visa,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.visa { background-image: url(../images/icons/credit-cards/visa.png) }
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.mastercard,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.mastercard { background-image: url(../images/icons/credit-cards/mastercard.png) }
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.laser,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.laser { background-image: url(../images/icons/credit-cards/laser.png) }
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.dinersclub,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.dinersclub { background-image: url(../images/icons/credit-cards/diners.png) }
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.maestro,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.maestro { background-image: url(../images/icons/credit-cards/maestro.png) }
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.jcb,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.jcb { background-image: url(../images/icons/credit-cards/jcb.png) }
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.amex,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.amex { background-image: url(../images/icons/credit-cards/amex.png) }
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry.discover,
.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number.discover { background-image: url(../images/icons/credit-cards/discover.png) }
.woocommerce-checkout #payment div.payment_box span.help {
    font-size: .857em;
    color: clr-gray;
    font-weight: 400;
}
.woocommerce-checkout #payment div.payment_box .form-row { margin: 0 0 1em }
.woocommerce-checkout #payment div.payment_box p:last-child { margin-bottom: 0 }
.woocommerce-checkout #payment div.payment_box:after {
    content: "";
    display: block;
    border: 8px solid clr-light-gray;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    position: absolute;
    top: -3px;
    left: 0;
    margin: -1em 0 0 2em;
}
.woocommerce-checkout #payment .payment_method_paypal .about_paypal {
    float: right;
    line-height: 52px;
    font-size: .83em;
}
.woocommerce-checkout #payment .payment_method_paypal img {
    max-height: 52px;
    vertical-align: middle;
}


/**
 * Woocommerce info msg
 */

.woocommerce-checkout 
    .woocommerce-message, .woocommerce-info, .woocommerce-error, .woocommerce-noreviews, p.no-comments
        width: 100%
        padding: 20px 0
        color: clr-black
        background-color: transparent
        margin: 0 auto  
        max-width: 720px
        display: none



/**
 * checkout form styles
 */

form.checkout
    margin: 50px auto 
    width: 100%
    max-width: 720px

    .form-row 
        margin-bottom: 30px

    input
        padding: 8px 12px

    .button
        @extend .btn
        transition: all 300ms ease
        padding: 13px 20px
        font-size: 14px
        letter-spacing: 0
        font-weight: 700
        cursor: pointer 
        border: 1px solid clr-red 

        &:hover, &:active, &:focus
            color: clr-red
            background-color: clr-white

        @media (max-width: 767px)
            float: none


// customer details 
#customer_details
    margin-bottom: 50px


// review order table
.woocommerce-checkout-review-order-table 
    border-radius: 2px
    background-color: clr-white
    text-align: left
    border-collapse: collapse
    margin-bottom: 30px
    width: 100%

    th, td 
        padding: 10px 0

    .product-total
        text-align: right
        font-weight: bolder;

    .woocommerce-Price-amount
        display: block 
        text-align: right


.woocommerce-checkout
    #payment 
        background-color: clr-white

    ul.payment_methods
        background-color:clr-light-gray

    .place-order
        padding: 50px 0 !important