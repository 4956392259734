#search-results {
  &:before {
    background: clr-red;
    content: "";
    width: 100%;
    height: 130px;
    display: block;
  }
  .wrapper {
    margin: 75px auto;
    max-width: 800px;
    .post {
      display: flex;
      margin-bottom: 50px;
    }
    h1 {
      color: clr-red;
      margin-bottom: 50px;
    }
    h2, h2 a {
      color: inherit;
      font-size: 40px;
      margin-top: 0;
      @media (max-width: 800px) {
        font-size: 20px;
        margin-bottom: 8px;
      }
    }
    .breadcrumbs, .breadcrumbs a {
      color: clr-red;
      font-size: 18px;
      text-transform: uppercase;
      font-family: oswald;
      @media (max-width: 800px) {
        font-size: 12px;
        margin: 8px 0;
      }
    }
    p {
      margin-bottom: 0;
      @media (max-width: 800px) {
        font-size: 12px;
        margin: 8px 0 0 0;
      }
    }
    .post-thumbnail {
      width: 24%;
      flex-shrink: 0;
      padding-right: 20px;
      display: flex;
      align-items: center;
      @media (max-width: 800px) {
        padding-right: 10px;
      }
    }
    .nav-links {
      display: flex;
      justify-content: space-between;
      width: 100%;
      a {
        color: clr-red;
      }
    }
  }
}