  .card-row {
    display: flex;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
    .column {
      width: 33.33%;
      background-color: clr-light-gray;
      padding: 7.5vw 5vw 4.5vw 5vw;
      flex-shrink: 0;
      position: relative;
      @media (max-width: 800px) {
        width: 100%;
        padding: 22.5vw 10vw 13.5vw 10vw;
      }
      .background {
        width: 100%;
        height: 15.8vw;
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: cover;
        overflow: hidden;
        @media (max-width: 800px) {
          height: 48vw;
        }
      }
      .inner {
        z-index: 1;
        position: relative;
        background: clr-white;
        padding: 13% 20%;
        text-align: center;
        min-height: 100%;
        .icon {
          height: 70px;
          margin: auto;
          margin-bottom: 25px;
        }
        &.inverted {
          background: clr-red;
          padding: 13% 10%;
          text-align: left;
          h3, p {
            color: clr-white;
          }
          h3, h3 i {
            font-size: 40px;
            @media (max-width: 1200px) {
              font-size: 30px;
            }
            @media (max-width: 1000px) {
              font-size: 25px;
            }
            @media (max-width: 800px) {
              font-size: 60px;
            }
            @media (max-width: 600px) {
              font-size: 34px;
            }
          }
          p i {
            margin-right: 15px;
            vertical-align: baseline;
          }
          p {
            font-family: Oswald;
          }
          p, p i {
            font-size: 32px;
            margin-top: 10px;
            @media (max-width: 1200px) {
              font-size: 26px;
            }
            @media (max-width: 1000px) {
              font-size: 20px;
            }
            @media (max-width: 800px) {
              font-size: 54px;
            }
            @media (max-width: 600px) {
              font-size: 28px;
            }
          }
          .btn {
            color: clr-white;
            &:hover {
              color: inherit;
            }
          }
        }
        h3 {
          color: clr-red;
          margin: 0;
          font-size: 24px;
          line-height: 125%;
          @media (max-width: 1400px) {
            font-size: 20px;
          }
          @media (max-width: 800px) {
            font-size: 24px;
          }
          @media (max-width: 400px) {
            font-size: 20px;
          }
        }
        p {
          margin: 0;
          margin-top: 25px;
          font-size: 16px;
          @media (max-width: 1400px) {
            font-size: 14px;
          }
          @media (max-width: 800px) {
            font-size: 16px;
          }
          @media (max-width: 400px) {
            font-size: 14px;
          }
        }
        .btn {
          margin-top: 25px;
          padding: 20px 10px;
          font-size: 13px;
          width: calc(100% - 60px);
          color: inherit;
          position: absolute;
          bottom: 30px
          left: 30px
          @media (max-width: 1400px) {
            font-size: 12px;
          }
          &:hover {
            color: clr-white;
          }
        }
      }
    }
    .slick-dots {
      bottom: -28px;
      li {
        &.slick-active button {
          background: clr-red
        }
        button {
          background: clr-light-gray;
          height: 15px;
          width: 15px;
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .form {
    flex-grow: 1;
    background: clr-light-gray;
    color: clr-white;
    padding-bottom: 4.5%;
    .inner {
      background: clr-red;
      padding: 4.5% 6.5% 4.5% 6%;
      height: 100%;
    }

    h3 {
      margin: 0;
      font-size: 24px;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 125%;
      margin: 20px 0;
      @media (max-width: 1200px) {
        margin-bottom: 20px;
      }
    }
    input, textarea {
      background: none;
      border: 2px solid clr-white;
      width: 100%;
      padding: 8px 10px;
      margin-bottom: 10px;
      font-size: 14px;
      color: clr-white;
      &::placeholder {
        color: rgba(255,255,255,.7);
      }
    }
    textarea {
      min-height: 82px;
    }
    .btn {
      margin-top: 40px;
      padding: 20px 30px;
      font-size: 12px;
    }
    .flex-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .right, .left {
        width: calc(50% - 10px);
        @media (max-width: 1000px) {
          width: 100%;
        }
      }
    }
  }
