.woocommerce form .form-row {
    padding: 3px;
    margin: 0 0 6px;
}
.woocommerce form .form-row [placeholder]:focus::-webkit-input-placeholder {
    -webkit-transition: opacity .5s .5s ease;
    -moz-transition: opacity .5s .5s ease;
    transition: opacity .5s .5s ease;
    opacity: 0;
}
.woocommerce form .form-row label { line-height: 2 }
.woocommerce form .form-row label.hidden { visibility: hidden }
.woocommerce form .form-row label.inline { display: inline }
.woocommerce form .form-row select {
    cursor: pointer;
    margin: 0;
}
.woocommerce form .form-row .required {
    color: red;
    font-weight: 700;
    border: 0;
    text-decoration: none;
}
.woocommerce form .form-row .input-checkbox {
    display: inline;
    margin: -2px 8px 0 0;
    text-align: center;
    vertical-align: middle;
}
.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    outline: 0;
    line-height: 1;
    padding: 4px 8px
    border: 1px solid alpha(clr-black, 0.3)
    border-radius: 2px
}
.woocommerce form .form-row textarea {
    height: 4em;
    line-height: 1.5;
    display: block;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.woocommerce form .form-row .select2-container {
    width: 100%;
    line-height: 2em;
}
.woocommerce form .form-row.woocommerce-invalid .select2-container,
.woocommerce form .form-row.woocommerce-invalid input.input-text,
.woocommerce form .form-row.woocommerce-invalid select { border-color: clr-red }
.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select { border-color: clr-success }
.woocommerce form .form-row ::-webkit-input-placeholder { line-height: normal }
.woocommerce form .form-row :-moz-placeholder { line-height: normal }
.woocommerce form .form-row :-ms-input-placeholder { line-height: normal }
.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
    border: 1px solid clr-light-gray;
    padding: 20px;
    margin: 2em 0;
    text-align: left;
    border-radius: 5px;
}
