.logo-list
    list-style: none 
    margin: 0
    padding: 0
    text-align: left

.logo-list-item
    display: inline-block 
    width: 18%
    margin: 0 3.5%
    text-align: left
    @media (max-width: 992px)
        width: 23%
        margin: 0 5% 100px 5%

    @media (max-width: 768px)
        width: 40%
        margin: 0 5% 100px 5%

    @media (max-width: 480px)
        width: calc(100% - 50px);
        margin: 0 20px 80px 20px

    a  
        display: inline-block

    img 
        display: inline-block 
        width: 100% 
        max-width: 252px
        margin: 80px 0;