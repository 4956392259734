.btn
  padding: 25px 34px
  letter-spacing: 0.8px
  cursor: pointer
  background: clr-red
  border: none
  color: clr-white
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 1200px)
    padding: 15px 30px
    font-size: 14px
  +respond-on(600px)
    font-size: 11px
    padding: 17px 23px
  &.ghost
    background: none
    border: 2px solid clr-white
    transition: .3s
    &:hover
      background: clr-white
      color: clr-red
  &.inverted-ghost
    background: none
    border: 2px solid clr-red
    color: clr-red
    transition: .3s
    &:hover
      background: clr-red
      color: clr-white
  &.white
    background: clr-white
    color: clr-red
  &:focus
    outline: none
  &.small
    padding: 15px 34px
    font-size: 14px
  &.full-width
    width: 100%
