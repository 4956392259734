
*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


// @-moz-document url-prefix() {
//   body {
//     font-weight: lighter !important;
//   }
// }

.lato-font
  font-family: 'Lato', sans-serif;

.oswald-font
  font-family: 'Oswald', sans-serif;


// .gira-sans-regular-font
//   font-family: "gira-sans"
//   font-style: normal;
//
// .questrial-font
//   font-family: 'Questrial', sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;

html,body
  @extend .lato-font
  color clr-gray

h1,h2,h3,h4,h5,h6, .head, header
  @extend .oswald-font
  // font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  font-style: normal;

// h2, .h2
//   font-size 40px
//   +respond-on(767px)
//     font-size: 31px
//
// h3, .h3
//   font-size 30px
//   font-weight 500
//   +respond-on(767px)
//     font-size: 19px
//
// h4,.h4
//   font-size 25px
//
// h6
//   font-size: 13px
//   +respond-on(767px)
//     font-size: 11px

// p, li, span
//   font-size 18px
//   +respond-on(767px)
//     font-size 16px
//   &.small
//     font-size 16px
//   &.extra-small
//     font-size: 14px
//   &.big
//     font-size 25px
//   &.extra-big
//     font-size 25px
//     +respond-on(767px)
//       font-size 18px
a
  text-decoration: none


// h1,h2,h3,h4,h5,p,li,a
//   &.red-dark
//     color: brand-red-dark
//   &.gray
//     color text-gray

.text-right
  text-align: right;
