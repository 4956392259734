// Fonts mixin
font-url(file)
  return 'fonts/' + file

webfont(family, file, hack-chrome-windows = false, weight = 'normal')
  @font-face
    font-family family
    src url(font-url(file + '.eot'))
    src url(font-url(file + '.eot?#iefix')) format('embedded-opentype'),
        url(font-url(file + '.woff')) format('woff'),
        url(font-url(file + '.ttf')) format('truetype'),
        url(font-url(file + '.svg#'+ family)) format('svg')
    font-weight weight
    font-style normal
  if hack-chrome-windows
    @media screen and (-webkit-min-device-pixel-ratio:0)
      @font-face
        font-family family
        src url(font-url(file + '.svg#'+ family)) format('svg')

// General mixin

placeholder()
  &::-webkit-input-placeholder
    {block}
  &:-moz-placeholder
    {block}
  &::-moz-placeholder
    {block}
  &:-ms-input-placeholder
    {block}

// Responsive mixin

respond-on($width)
  $width = $width
  media = 'all and (max-width: %s)' % $width
  @media media
    {block}

respond-below($width)
  $width = $width - 1
  media = 'all and (max-width: %s)' % $width
  @media media
    {block}

respond-above($width)
  media = 'all and (min-width: %s)' % $width
  @media media
    {block}

respond-between($min-width, $max-width)
  $max-width = $max-width - 1
  media = 'all and (min-width: %s) and (max-width: %s)' % ($min-width $max-width)
  @media media
    {block}

/***********************\
    Mixins
\***********************/
clearfix()
	&:before, &:after
		clear both
		content ''
		display table

vertical-aligns()
	position relative
	top 50%
	transform translateY(-50%)

respond-on($width)
	$width = $width
	media = 'all and (max-width %s)' % $width
	@media media
		{block}
