.product-separator
    display: flex
    margin: 50px auto
    position: relative
    +respond-on(600px)
      height: 212px
      display: flex
      margin: -5px 0 0
    .image-wrapper
      overflow: hidden
      &:first
        margin-right: 40px
      img
        max-width: none
        max-height: none
        height: 100%
    .break
      width: 40px
    .card
      position: absolute
      background: clr-white
      color: clr-red
      padding: 55px 60px 60px
      top: 55%
      transform: translateY(-50%)
      left: 19%
      width: 400px
      @media (max-width: 850px)
        left: 130px
      +respond-on(600px)
        left: 17%
        padding: 21px 12px
        max-width: 42%
        top: 50%
      h3
        font-size: 42px
        line-height: 110%
        margin: 0 0 60px 0
        +respond-on(600px)
          font-size: 16px
          margin: 0
          max-width: 83%
          line-height: 130%
          letter-spacing: 0
      .btn
        +respond-on(600px)
          letter-spacing: 0.4px
          padding: 10px 20px;