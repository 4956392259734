.error-404
    background-color: clr-black
    min-height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    @media (max-width: 980px)
        padding: 200px 0

    .wrapper
        @extend $clearfix
        width: 100%
        text-align: center

.error-404-image 
    display: inline-block
    width: 400px
    vertical-align: middle
    margin-right: 200px
    @media (max-width: 980px)
        margin-right: 0
        display: block;
        width: 100%;

.error-404-text
    vertical-align: middle
    display: inline-block
    width: 350px
    color: clr-white
    text-align: left
    @media (max-width: 980px)
        display: block;
        width: 100%;
        text-align: center
    h1
        font-size: 200px
        margin: 0 0 50px 0

    p  
        font-size: 30px
        margin-bottom: 65px

    .btn   
        color: clr-white