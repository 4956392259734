#partner-login {
  padding-top: 110px;
  h1 {
    text-align: center;
    color: clr-red;
    font-size: 70px;
    margin-bottom: 40px;
    @media (max-width: 800px) {
      font-size: 40px;
      padding: 0 25px;
    }
  }
  .backdrop {
    background-image: url("images/partner-resources.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 0;
    width: 100%;
    padding: 8%
    position: relative;
    @media (max-width: 800px) {
      background-size: 150%;
    }
    .card {
      background: clr-white;
      text-align: center;
      padding: 65px;
      z-index: 99;
      position: relative;
      @media (max-width: 1000px) {
        padding: 25px;
      }
      h2 {
        margin: 0;
        letter-spacing: 1px;
        @media (max-width: 800px) {
          font-size: 18px;
        }
      }
      p {
        margin: 0;
        font-size: 24px;
        margin-bottom: 7px;
        @media (max-width: 1000px) {
          font-size: 16px;
        }
        a {
          color: clr-red;
        }
      }
      form {
        margin 50px 0;
        label {
          display: block;
          font-size: 16px;
          margin: auto;
          margin-bottom: 3px;
          width: 300px;
          text-align: left;
          @media (max-width: 550px) {
            width: 100%;
          }
        }
        #wp-submit {
          padding: 17px 65px;
          font-size: 12px;
          margin-top: 15px;
          width: auto;
          cursor: pointer
          text-transform: uppercase;
          transition: .4s all;
          &:hover {
            background: clr-red;
            color: clr-white;
          }
        }
        input {
          border: 2px solid clr-red;
          padding: 10px 15px;
          background: none;
          font-size: 14px;
          width: 300px;
          @media (max-width: 550px) {
            width: 100%;
          }
        }
      }
      .section {
        border: 2px solid clr-red;
        width: 100%;
        margin-top: 40px;
        text-align: left;
        .title {
          background: clr-red;
          margin: 0;
          padding: 10px;
          color: clr-white;
          font-size: 22px;
          @media (max-width: 600px) {
            font-size: 16px;
          }
        }
        .description {
          padding: 20px;
          padding-bottom: 0;
          font-size: 18px;
          margin 0;
          @media (max-width: 700px) {
            font-size: 14px;
          }
        }
        .inner {
          font-size: 16px;
          display: flex;
          flex-wrap: wrap;
          padding: 20px;
          @media (max-width: 700px) {
            display: block;
          }
          a {
            margin-right: 20px;
            text-align: center;
            @media (max-width: 1000px) {
              font-size: 12px;
              margin-right: 10px;
            }
            @media (max-width: 800px) {
              margin-top: 20px;
              margin-right: 0;
              width: 100%;
            }
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
    &::after {
      content: "";
      display: block;
      background: clr-red;
      width: 100%;
      height: 50%;
      min-height: calc(100% - 61vw);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}