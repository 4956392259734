.archive 
  .product-catalog-text 
    font-size: 12px
    line-height: 1.5

    a   
      color:clr-gray
    
    h2
      font-size: 28px
      @media (max-width: 1000px)
        font-size: 22px;
      @media (max-width: 600px)
        font-size: 18px;

    .price 
      display: none

    .product-button-wrapper 
      margin-top: 15px

      .btn-inverted-ghost
        display: inline-block
        padding: 15px
        color:clr-gray
        font-size: 12px
        font-weight: 500