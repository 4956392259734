
.product-catalog-section
  background-color: clr-light-gray
  padding: 80px 20px
  @media (max-width: 800px)
    padding: 40px 0

  .section-heading
    margin: 0 0 70px 0
    text-align: left
    color: clr-red
    font-size: 68px
    letter-spacing: 3.2px
    line-height: 1.2
    @media (max-width: 1180px)
      font-size: 40px
      text-align: center
    +respond-on(600px)
      font-size: 36px
      letter-spacing: 1.1px

.product-catalog-list, .product-catalog-archive-list
  list-style: none
  margin: 0
  padding: 0
  text-align: left
  max-width: 100%
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

.product-catalog-item
  width: calc(50% - 10px);
  margin-bottom: 20px;
  display: flex
  padding: 20px
  background-color: clr-white
  text-align: left
  @media (max-width: 800px)
    width: 100%
    padding: 15px 10px
  &:nth-child(even)
      margin-right: 0
  .star-rating
    display: none

.product-catalog-item > .product-catalog-image, .product-catalog-item > a
  width: 35%
  padding-right: 20px
  margin-right: 20px
  border-right: 1px solid clr-lighter-gray
  flex-shrink: 0
  @media (max-width: 600px)
    padding-right: 10px
    margin-right: 10px
  img
    width: 100%
    height: auto

.product-catalog-text
  font-size: 16px

  h2
    line-height: 1.2
    margin: 0 0 15px 0

    a
      font-size: 28px
      color:clr-gray
      @media (max-width: 600px)
        font-size: 20px;

  p
    font-size: 12px
    line-height: 1.5
    margin-bottom: 15px

  .btn
    display: inline-block
    padding: 15px
    color:clr-gray
    font-size: 12px
    font-weight: 500
    @media (max-width: 600px)
      padding: 8px 10px
      font-size: 10px;

.woocommerce-pagination .page-numbers {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  li {
    padding: 0 5px;
    .current {
      color: clr-red;
    }
    a {
      color: clr-gray;
    }
  }
}
