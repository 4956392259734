#blog-archive
  background: clr-light-gray
  padding: 25px 0
  @media (max-width: 768px)
    padding: 10px 0
  .wrapper
    display: flex
  .articles
    flex-grow: 1
    article
      background: clr-white
      padding: 35px
      margin: 25px 0
      display: flex
      @media (max-width: 768px)
        padding: 15px
        margin: 10px 0
      .post-thumbnail
        padding-right: 35px
        margin-right: 35px
        border-right: 1px solid clr-lighter-gray
        flex-shrink: 0
        width: 215px
        @media (max-width: 768px)
          width: 130px
          margin-right: 15px
          padding-right: 12px
        @media (max-width: 375px)
          display: none;
        img
          width: 100%
      .description
        .info
          display: flex
          align-items: center
          justify-content: flex-start
          margin-bottom: 25px
          font-size: 14px
          @media (max-width: 768px)
            margin-bottom: 8px
            font-size: 11px
          & > div
            margin-right: 25px
            @media (max-width: 1000px)
              margin-right: 15px
            @media (max-width: 768px)
              margin-right: 10px
          i
            font-size: 13px
            @media (max-width: 768px)
              font-size: 11px
              vertical-align: middle
          .category a
            color: clr-red
          .author, .date
            a
              color: clr-gray
          .profile
            height: 45px
            width: 45px
            position: relative
            flex 0 0 auto
            @media (max-width: 768px)
              height: 30px
              width: 30px
              margin-right: 13px
            img
              border-radius: 50%
              padding: 3px
              border: solid 1px clr-lighter-gray
              @media (max-width: 768px)
                padding: 1px
            .stats
              position: absolute
              bottom: -2px
              right: -2px
              background: clr-red
              color: clr-white
              border-radius: 50%
              font-size: 12px
              width: 20px
              height: 20px
              display: flex
              align-items: center
              justify-content: center
              border: 1.1px solid clr-white
              @media (max-width: 768px)
                font-size: 10px
                height: 10px
                width: 10px
        h2
          margin: 0
          margin-bottom: 10px
          font-size: 25px
          a
            color: clr-gray
          @media (max-width: 768px)
            font-size: 16px
            letter-spacing: .15px
            margin-bottom: 3px
        .btn
          margin-top: 10px
          padding: 12px 22px
          letter-spacing: .5px
          color: clr-gray
          &:hover
            color: clr-white
          @media (max-width: 768px)
            padding: 6px 10px
            margin-top: 0
            border-width: 1px
            letter-spacing: .5px
        p
          font-size: 12px
          line-height: 125%
