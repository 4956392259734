/**
 * General dropdown selection
 */

select 
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none       /* remove default arrow */
    background-color: transparent
    background-image: url("images/icons/icon-chevron-down.svg")   /* add custom arrow */
    background-repeat: no-repeat
    background-position: right 10px center
    padding: 10px 40px 10px 15px
    width: 175px


/**
 * Selectric
 */

.selectric
    background: clr-light-gray
    // label font
    .label
        font-family: "Lato", sans-serif
        font-size: 16px

    // change select icon
    .button
        background: clr-light-gray;
        &::before
            content: ""
            position: absolute
            top: 0
            right: 0
            width: 38px
            height: 38px
            background-image: url("images/icons/icon-chevron-down.svg");
            background-repeat: no-repeat
            background-size: 20px 12px
            background-position: center center
        &::after
            border: 0 !important