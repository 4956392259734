#blog-article
  padding-top: 130px
  background: clr-light-gray
  .content
    padding: 25px 0
    @media (max-width: 1200px)
      padding: 10px 0
    .wrapper
      display: flex
      @media (max-width: 768px)
        padding: 0
    .articles
      flex-grow: 1
      article
        background: clr-white
        padding: 30px
        margin: 25px 0
        @media (max-width: 768px)
          padding: 15px
          margin: 10px 0
        .entry-title
          margin: 0
          font-size: 40px
          color: clr-gray
          @media (max-width: 768px)
            font-size: 33px
        .info
          display: flex
          align-items: center
          justify-content: flex-start
          margin: 18px 0
          font-size: 14px
          @media (max-width: 768px)
            font-size: 11px
            margin-bottom: 8px
          &>div
            margin-right: 25px
            @media (max-width: 768px)
              margin-right: 6.5px
          i
            font-size: 13px
            @media (max-width: 768px)
              font-size: 7px
              vertical-align: middle
          .category a
            color: clr-red
          .author, .date
            a
              color: clr-gray
          .profile
            height: 70px
            width: 70px
            position: relative
            flex: 0 0 auto;
            @media (max-width: 768px)
              height: 20px
              width: 20px
              margin-right: 13px
            img
              border-radius: 50%
              padding: 3px
              border: solid 1px clr-lighter-gray
              @media (max-width: 768px)
                padding: 1px
            .stats
              position: absolute
              bottom: 0
              right: 0
              background: clr-red
              color: clr-white
              border-radius: 50%
              font-size: 12px
              width: 25px
              height: 25px
              display: flex
              align-items: center
              justify-content: center
              border: 1.1px solid clr-white
              @media (max-width: 768px)
                font-size: 7px
                height: 10px
                width: 10px
    .entry-content
      clearfix()

    #comments
      padding-top: 140px
    #commentform
      display: flex;
      flex-wrap: wrap;
      #submit
        @extend .btn
        @extend .btn.inverted-ghost
        padding: 22px 30px
        font-size: 14px
      .comment-notes, .comment-form-comment
        flex: 1 1 100%
        margin: 5px 0
        label
          display: none;
      .comment-form-author, .comment-form-email, .comment-form-url
        flex: 0 1 50%
        margin: 5px 0
        label
          display: none;
      .comment-form-author, .comment-form-url
        padding-right: 10px
      .comment-form-email
        padding-left: 10px
      label
        display: block;
      textarea, input:not(.submit)
        width: 100%
        border 2px solid clr-lighter-gray
        padding: 15px 20px
        font-size: 12px
      .comment-form-cookies-consent
        width: 100%
        input
          width: auto
          display: inline-block;
        label
          display: inline-block !important
          padding: 0 15px
