.testimonials {
  position: relative;
  padding-top: 135px;
  padding-bottom: 150px;
  @media (max-width: 650px) {
    padding-top: 78px;
    padding-bottom: 75px;
  }
  .testimonial {
    padding-left: 30px;
    font-size: 22px;
    letter-spacing: 1.5px;
    line-height: 120%;
    font-weight: 400;
    max-width: 58.8%;
    margin: 0 auto;
    display: block !important
    @media (max-width: 1200px) {
      font-size: 18px;
    }
    @media (max-width: 650px) {
      max-width: 70%;
      font-size: 14px;
    }

    .name {
      margin-top: 60px;
      color: clr-red;
      font-size: 22px;
      text-transform: uppercase;
      line-height: 100%;
      font-weight: bold;
      @media (max-width: 1200px) {
        margin-top: 30px;
        font-size: 16px;
      }
    }
    .company {
      color: clr-red;
      font-size: 18px;
      line-height: 125%;
      text-transform: uppercase;
      @media (max-width: 1200px) {
        font-size: 16px;
      }
    }
  }
  .quote {
    position: absolute;
    width: 136px;
    top: 10%;
    left: 9.8%;
    z-index: 1
    @media (max-width: 1200px) {
      width: 100px;
      top: 10%;
      left: 9.8%;
    }
    @media (max-width: 650px) {
      width: 41px;
      top: 4.5%;
      left: 8.6%;
    }
  }

  .slick-arrow {
    width: 50px;
    height: 50px;
    opacity: 0.5;
    @media (max-width: 650px) {
      width: 25px;
      height: 25px;
    }
    &:before {
      content "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-top: 2px solid clr-black;
      border-left: 2px solid clr-black;
    }
    &.slick-prev {
      transform: rotate(-45deg);
      left: 40px;
      z-index: 50;
      @media (max-width: 650px) {
        left: 20px;
      }
    }
    &.slick-next {
      transform: rotate(135deg);
      right: 40px;
      @media (max-width: 650px) {
        right: 20px;
      }
    }
  }

  .slick-dots {
    bottom: -105px;
    li button {
      background: clr-light-gray;
      height: 15px;
      width: 15px;
      &[aria-selected] {
        background: clr-red;
      }
      &::before {
        display: none;
      }
    }
  }
}