.product-case-study 
    position: relative;


.product-case-study-text
    background-color: clr-red;
    padding: 80px;

    &:before 
        content: "";
        background-color: clr-white;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        z-index: 1;

    h2 
        font-family: 'Oswald', sans-serif;
        font-size: 42px;
        font-weight: 400;
        color: clr-white;
        width: 40%;
    
    p 
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: clr-white;
        margin-bottom: 80px;
        width: 40%;


    .product-content-container
        position: relative;

.product-case-study-preview-img 
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    width: 56.25%;
    height: auto;


//
// Product case study v2
//

.product-case-study-v2
    @extend $clearfix;
    position: relative;
    margin-bottom: 50px;
    
.product-case-study-v2-text
    background-color: clr-red;
    padding: 80px;
    float: left;
    width: 48%;

    h2 
        font-family: 'Oswald', sans-serif;
        font-size: 42px;
        font-weight: 400;
        color: clr-white;
    
    p 
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: clr-white;
        margin-bottom: 80px;

.product-case-study-v2-media
    float: right;
    width: 48%;



//
// Product case study v3
//

.product-case-study-v3
    @extend $clearfix;
    position: relative;
    margin-bottom: 50px;
    
.product-case-study-v3-text
    background-color: clr-red;
    padding: 80px;
    float: right;
    width: 48%;

    h2 
        font-family: 'Oswald', sans-serif;
        font-size: 42px;
        font-weight: 400;
        color: clr-white;
    
    p 
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: clr-white;
        margin-bottom: 80px;

.product-case-study-v3-test-results
    float: left;
    width: 48%;