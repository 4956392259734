#custom {
  .top-padding {
    background: clr-light-gray;
    padding-top: 130px;
  }


  .summary {
    display: flex;
    padding-top: 45px;
    flex-wrap: wrap-reverse;
    @media (max-width: 1200px) {
      padding: 15px 35px;
    }
    .content {
      padding-right: 30px;
      width: 50%;
      @media (max-width: 1200px) {
        width: 100%;
        padding-top: 10px;
      }
      h1 {
        margin: 0;
        margin-bottom: 25px;
        font-size: 70px;
        color: clr-red;
        @media (max-width: 1200px) {
          font-size: 40px;
        }
      }
      p {
        line-height: 125%;
      }
      .btn {
        margin: 30px 0;
        padding: 25px 60px;
        color: inherit;
        &:hover {
          color: clr-white;
        }
      }
    }
    .video {
      margin-top: 15px;
      width: 50%;
      flex-shrink: 0;
      @media (max-width: 1200px) {
        width: 100%;
      }
      .iframe-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }


  .logo-slider {
    margin-bottom: 50px;
    margin-top: 40px;
    @media (max-width: 1200px) {
      margin: 0;
    }
    .slide {
      height: 100px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      img {
        max-height: 100%;
        max-width: 70%;
        &:focus {
          outline: none;
        }
      }
    }
  }


  .breadcrumb, .breadcrumbs {
    background: clr-light-gray;
    color: clr-lighter-gray;
    padding: 12px 0;
    .selected {
      color: clr-gray;
    }
  }


  .features {
    text-align: center;
    .bottom {
      background: clr-light-gray;
      .btn {
        color: inherit;
        margin: 0 auto 50px auto;
        &:hover {
          color: clr-white;
        }
      }
    }
    h2 {
      color: clr-red;
      text-align: left;
      font-size: 65px;
      margin: 45px 50px;
      @media (max-width: 1200px) {
        font-size: 40px;
      }
    }
  }


  .steps {
    margin: 85px auto 0;
    @media (min-width: 701px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    +respond-on(768px){
      padding: 0 40px
    }
    +respond-on(425px){
      padding: 0 30px
    }
    .title {
      width: 100%;
      flex-shrink: 0;
      display: flex;
      padding-bottom: 130px;
      @media (max-width: 700px) {
        padding-bottom: 50px;
        iframe {
          display: none;
        }
      }
      .right {
        width: 50%;
        padding: 40px 110px;
        flex-shrink: 0;
        @media (max-width: 1000px) {
          padding: 40px 30px 0 60px;
        }
        @media (max-width: 800px) {
          padding: 40px 0 0 30px;
          width: 40%
        }
        @media (max-width: 700px) {
          padding: 0;
          width: 100%;
        }
        h2 {
          font-size: 65px;
          margin: 0;
          color: clr-red;
          @media (max-width: 1000px) {
            font-size: 50px;
          }
          @media (max-width: 700px) {
            font-size: 30px;
            text-align: center;
          }
        }
        .btn.top {
          margin-top: 60px;
          @media (max-width: 700px) {
            display: none;
          }
        }
      }
    }
    .step {
      display: flex;
      flex-wrap: wrap;
      @media (min-width: 701px) {
        margin-bottom: 80px;
        width: 50%;
        max-width: 500px;
      }
      .icon {
        background: clr-red;
        width: 180px;
        height 180px;
        padding: 30px;
        @media (max-width: 1200px) {
          width: 95px;
          height: auto;
          margin-left: 5px;
          padding: 15px;
        }
      }
      .content {
        padding-left: 50px;
        width: calc(100% - 180px);
        @media (max-width: 1200px) {
          width: calc(100% - 105px);
          padding-left: 15px;
          max-width: 250px;
        }
        h3 {
          margin-top: 0;
          margin-bottom: 25px;
          font-size: 27px;
          color: clr-red;
          @media (max-width: 1200px) {
            font-size: 15px;
            margin-bottom: 10px;
          }
        }
        p {
          line-height: 125%;
          @media (max-width: 1200px) {
            font-size: 12px;
            margin: 0;
          }
        }
      }
      @media (max-width: 700px) {
        &::after {
          content: "";
          display: block;
          height: 180px;
          border-left: 2px solid clr-red;
          margin-left: 90px;
          width: 100%;
          @media (max-width: 1200px) {
            margin-left: 50px;
            height: 140px;
          }
        }
        &:last-of-type:after {
          display: none;
        }
      }
    }
    .btn {
      &.bottom {
        left: 50%;
        transform: translateX(-50%);
        display: inline-block
        position: relative;
        margin: 90px auto;
        @media (min-width: 701px) {
          display: none;
        }
      }
      color: inherit;
      padding-left: 60px;
      padding-right: 60px;
      &:hover {
        color: clr-white;
      }
    }
  }

  .video-wrapper {
    margin-bottom: 50px;
    @media (min-width: 1200px) {
      position: relative;
      display: flex;
      justify-content: flex-end;
    }
    .video {
      @media (min-width: 1200px) {
        position: absolute;
        right: calc(52% - 160px);
        top: 50%;
        transform: translateY(-50%);
        width: calc(48% + 160px);
      }
      @media (min-width: 1600px) {
        width: 900px;
      }
      .iframe-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    .description {
      background: clr-light-gray;
      padding: 25px;
      @media (min-width: 1200px) {
        width: 52%;
        padding: 120px 0 120px 300px;
      }
      h1 {
        font-size: 40px;
        margin-top: 0;
        @media (min-width: 1200px) {
          font-size: 42px;
          max-width: 270px;
        }
      }
      p {
        line-height: 125%;
        letter-spacing: .5px;
        @media (min-width: 1200px) {
          max-width: 270px;
        }
      }
      .btn {
        margin-top: 50px;
        color: inherit;
        @media (max-width: 1200px) {
          margin-top: 20px;
        }
        &:hover {
          color: clr-white;
        }
      }
    }
  }
}
