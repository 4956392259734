#about-us {
  .summary {
    background: clr-red;
    color: clr-white;
    padding-top: 160px;
    min-height: 85vh;
    @media (max-width: 1000px) {
      padding-top: 130px;
    }
    .wrapper {
      display: flex;
      flex-wrap: wrap-reverse;
      .content {
        padding-right: 90px;
        padding-bottom: 35px;
        width: 35%;
        @media (max-width: 1200px) {
          width: 50%;
          padding-right: 30px;
          padding-bottom: 30px;
          font-size: 14px;
          padding-left: 10px;
        }
        @media (max-width: 700px) {
          padding-right: 10px;
          padding-bottom: 10px;
          width: 100%;
        }
        h1 {
          font-size: 42px;
          margin: 20px 0 30px 0;
        }
        .btn {
          margin-top: 10px;
        }
      }
      .video {
        margin-top: 25px;
        width: 65%;
        flex-shrink: 0;
        @media (max-width: 1200px) {
          margin-top: 100px;
          width: 50%;
        }
        @media (max-width: 700px) {
          width: 100%;
          margin-top: 25px;
        }
        .iframe-wrapper {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .awards {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    @media (max-width: 1000px) {
      // margin: 0;
    }
    .award {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px 10px;
      @media (max-width: 1000px) {
        padding: 15px 10px;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .team {
    background: clr-light-gray;
    padding-top: 75px;
    padding-bottom: 120px;
    @media (max-width: 1000px) {
      margin-top: 0;
    }
    h2 {
      margin: 0;
      margin-bottom: 50px;
      font-size: 70px;
      color: clr-red;
      @media (max-width: 1000px) {
        font-size: 40px;
      }
    }
    .members-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 50px;
    }
    .member {
      background: clr-white;
      padding: 30px;
      display: flex;
      align-items: flex-start;
      width: calc(50% - 10px);
      margin-bottom: 20px;
      @media (max-width: 700px) {
        width: 100%;
        padding: 15px;
      }
      h3 {
        font-size: 40px;
        margin: 0;
        @media (max-width: 1200px) {
          font-size: 24px;
        }
      }
      p {
        font-size: 24px;
        @media (max-width: 1200px) {
          font-size: 18px;
        }
      }
      .image {
        padding-right: 50px;
        margin-right: 40px;
        border-right: solid 1.5px clr-lighter-gray;
        @media (max-width: 1200px) {
          width: 130px;
          padding-right: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}