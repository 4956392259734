#contact-us
  padding-top: 160px
  +respond-on(1200px)
    padding-top: 120px
  .map
    width: 100%
    background: url('images/contact-us/map.png')
    background-position: 50% 44%
    background-size: cover
    height: 360px
    +respond-on(1200px)
      background-size: 200%
  .container
    display: flex
    justify-content: space-between
    padding: 60px 0
    +respond-on(1200px)
      padding: 30px 0 0 0
    +respond-on(900px) {
      flex-wrap: wrap
    }
    .contact-form
      background: clr-red
      width: 53vw
      padding: 40px calc((100vw - 1250px) / 2) 0 80px
      color: clr-white
      position: relative
      +respond-on(1200px)
        padding: 35px
        width: 100%
      h2
        font-size: 43px
        margin: 0
        @media (max-width: 1200px) {
          font-size: 28px;
        }
      p 
        margin: 0
        margin-top: 15px
        +respond-on(1200px)
          margin-top: 35px
      form
        padding-top: 30px
        display: flex
        justify-content: space-between
        +respond-on(1200px)
          display: block
          padding: 25px 20px 0 20px;
          text-align: center
        & > div
          flex-grow: 1
          &:first-of-type
            padding-right: 50px
            +respond-on(1200px)
              padding: 0
        .btn
          position: absolute
          bottom: 40px
          padding: 17px 35px
          font-size: 12px
          +respond-on(1200px)
            position: static
            margin-top: 20px
        .input-wrapper
          position: relative
          &::after
            content: ""
            position: absolute
            top: 15px
            left: 4.5px
            width: 0
            height: 0
            border-left: 5px solid clr-white
            border-right: 5px solid transparent
            border-top: 5px solid transparent
            border-bottom: 5px solid transparent
          textarea 
            min-height: 73px
          input, textarea
            border-top: 1.5px solid clr-white
            border-bottom: 1.5px solid clr-white
            border-left: 5px solid clr-white
            border-right: 5px solid clr-white
            background: none
            color: clr-white
            width: 100%
            margin: 4px 0
            padding: 8px 15px
            font-size: 12px
            &::placeholder
              color: clr-white
    .contact-info
      padding-top: 0;
      padding-left: calc((100vw - 1250px) / 2)
      padding-bottom: 20px
      +respond-on(1200px)
        padding: 0 40px
        width: 100%
      h2
        font-size: 43px
        margin-top: 0
        margin-bottom: 55px
        color: clr-red
        +respond-on(1200px)
          margin-bottom: 35px
          font-size: 28px;
      .section
        margin-bottom: 24px
        padding-left: 48px
        position: relative
        +respond-on(600px)
          padding-left: 42px
        img
          position: absolute
          height: 30px
          top: 5px
          left: 0
          &.first-icon
            top: 0
        p
          margin: 0
          line-height: 129%
          font-size: 14px
          letter-spacing: 0.7px
          a
            color: inherit
          &.uppercase
            text-transform: uppercase;
        .head
          font-size: 14px
          letter-spacing: 0.6px
          line-height: 144%
      .social-icons
        margin-top: 85px
        display: flex
        justify-content: space-between
        max-width: 250px;
        +respond-on(1200px)
          margin: 35px 0
        img
          height: 15px
          display: block
