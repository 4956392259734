.pure-g {
    letter-spacing: -.31em;
    *letter-spacing: normal;
    *word-spacing: -.43em;
    text-rendering: optimizespeed;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    display: -ms-flexbox;
    -ms-flex-flow: row wrap;
    -ms-align-content: flex-start;
    -webkit-align-content: flex-start;
    align-content: flex-start;
    flex-flow: row wrap;
}
.opera-only:-o-prefocus,
.pure-g {
    word-spacing: -.43em;
}
.pure-u {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}
.pure-g [class *="pure-u"] {
}
.pure-u-1,
.pure-u-1-1,
.pure-u-1-12,
.pure-u-1-2,
.pure-u-1-24,
.pure-u-1-3,
.pure-u-1-4,
.pure-u-1-5,
.pure-u-1-6,
.pure-u-1-8,
.pure-u-10-24,
.pure-u-11-12,
.pure-u-11-24,
.pure-u-12-24,
.pure-u-13-24,
.pure-u-14-24,
.pure-u-15-24,
.pure-u-16-24,
.pure-u-17-24,
.pure-u-18-24,
.pure-u-19-24,
.pure-u-2-24,
.pure-u-2-3,
.pure-u-2-5,
.pure-u-20-24,
.pure-u-21-24,
.pure-u-22-24,
.pure-u-23-24,
.pure-u-24-24,
.pure-u-3-24,
.pure-u-3-4,
.pure-u-3-5,
.pure-u-3-8,
.pure-u-4-24,
.pure-u-4-5,
.pure-u-5-12,
.pure-u-5-24,
.pure-u-5-5,
.pure-u-5-6,
.pure-u-5-8,
.pure-u-6-24,
.pure-u-7-12,
.pure-u-7-24,
.pure-u-7-8,
.pure-u-8-24,
.pure-u-9-24 {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}
.pure-u-1-24 {
    width: 4.1667%;
    *width: 4.1357%;
}
.pure-u-1-12,
.pure-u-2-24 {
    width: 8.3333%;
    *width: 8.3023%;
}
.pure-u-1-8,
.pure-u-3-24 {
    width: 12.5%;
    *width: 12.469%;
}
.pure-u-1-6,
.pure-u-4-24 {
    width: 16.6667%;
    *width: 16.6357%;
}
.pure-u-1-5 {
    width: 20%;
    *width: 19.969%;
}
.pure-u-5-24 {
    width: 20.8333%;
    *width: 20.8023%;
}
.pure-u-1-4,
.pure-u-6-24 {
    width: 25%;
    *width: 24.969%;
}
.pure-u-7-24 {
    width: 29.1667%;
    *width: 29.1357%;
}
.pure-u-1-3,
.pure-u-8-24 {
    width: 33.3333%;
    *width: 33.3023%;
}
.pure-u-3-8,
.pure-u-9-24 {
    width: 37.5%;
    *width: 37.469%;
}
.pure-u-2-5 {
    width: 40%;
    *width: 39.969%;
}
.pure-u-10-24,
.pure-u-5-12 {
    width: 41.6667%;
    *width: 41.6357%;
}
.pure-u-11-24 {
    width: 45.8333%;
    *width: 45.8023%;
}
.pure-u-1-2,
.pure-u-12-24 {
    width: 50%;
    *width: 49.969%;
}
.pure-u-13-24 {
    width: 54.1667%;
    *width: 54.1357%;
}
.pure-u-14-24,
.pure-u-7-12 {
    width: 58.3333%;
    *width: 58.3023%;
}
.pure-u-3-5 {
    width: 60%;
    *width: 59.969%;
}
.pure-u-15-24,
.pure-u-5-8 {
    width: 62.5%;
    *width: 62.469%;
}
.pure-u-16-24,
.pure-u-2-3 {
    width: 66.6667%;
    *width: 66.6357%;
}
.pure-u-17-24 {
    width: 70.8333%;
    *width: 70.8023%;
}
.pure-u-18-24,
.pure-u-3-4 {
    width: 75%;
    *width: 74.969%;
}
.pure-u-19-24 {
    width: 79.1667%;
    *width: 79.1357%;
}
.pure-u-4-5 {
    width: 80%;
    *width: 79.969%;
}
.pure-u-20-24,
.pure-u-5-6 {
    width: 83.3333%;
    *width: 83.3023%;
}
.pure-u-21-24,
.pure-u-7-8 {
    width: 87.5%;
    *width: 87.469%;
}
.pure-u-11-12,
.pure-u-22-24 {
    width: 91.6667%;
    *width: 91.6357%;
}
.pure-u-23-24 {
    width: 95.8333%;
    *width: 95.8023%;
}
.pure-u-1,
.pure-u-1-1,
.pure-u-24-24,
.pure-u-5-5 {
    width: 100%;
}
