#product-page
  .hero
    padding: 130px 0 50px
    background: clr-red
    @media (max-width: 1200px)
      padding: 140px 15px 50px 15px
    .wrapper
      display: flex
      flex-wrap: wrap-reverse
      position: relative;
    .content
      padding-right: 30px
      width: 50%
      color: clr-white
      @media (max-width: 1200px)
        width: 100%
        padding-top: 10px
      h2
        margin: 0
        margin-bottom: 15px
        font-size: 40px
        padding-top: 10px
        color: clr-white
        @media (max-width: 1200px)
          font-size: 30px
      p
        line-height: 125%
        @media (max-width: 1200px)
          font-size: 14px
      .btn
        margin-top: 30px
        padding: 25px 15px
    .video, .image
      margin-top: 15px
      width: 50%
      flex-shrink: 0
      @media (max-width: 1200px)
        width: 100%
      .iframe-wrapper
        position: relative
        padding-bottom: 56.25%
        /* 16:9 */
        iframe
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%

  .breadcrumbs
    // background: clr-light-gray;
    color: clr-lighter-gray
    padding: 12px 0
    @media (max-width: 1200px)
      font-size: 12px
      .head
        letter-spacing: .5px
    .selected
      color: clr-gray

  .features
    text-align: center
    background: clr-light-gray
    display: block;
    & > .wrapper
      padding: 0 20px
    h2 {
      color: clr-red;
      text-align: left;
      font-size: 65px;
      padding-top: 45px
      margin: 0 0 45px;
      @media (max-width: 1200px) {
        font-size: 40px;
      }
    }

  section.product
    width: 100%
    background: clr-light-gray
    padding: 80px 20px
    #tab-description
      font-size: 16px;
    .product_title
      color: clr-red
      text-align: left;
      font-size: 52px;
      line-height: 1.2
      margin: 0 0 20px;
      @media (max-width: 1200px) {
        font-size: 40px;
      }
    .woocommerce-product-rating
      display: flex;
      align-items: center;
      .star-rating
        display: inline-block;
        span:before
          color: clr-red
    #reviews
      .woocommerce-review-link
        color: clr-red
        padding-left: 15px
      .woocommerce-review__dash,.woocommerce-review__published-date, .avatar
        display: none;
    #tab-title-reviews
      display: none
    .commentlist
      list-style: none
      padding: 0
      .comment_container
        display: flex
        margin: 10px 0
        .comment-text
          padding: 0 10px
        p
          margin: 0
    #reply-title
      font-size: 24px
      text-transform: capitalize
      margin-bottom: 5px
      display: block
    .comment-form
      .comment-form-comment label
        display: block
      .stars
        margin: 0
      #comment
        height: 75px
      #submit
        padding: 14px 25px
        letter-spacing: 0.8px
        cursor: pointer
        display: inline-block
        text-transform: uppercase
        background: clr-red
        border: none
        color: clr-white
        font-size: 14px
    .add-more-related-products-container
      margin: 40px 0
      display: flex;
      padding: 0 20px
      align-items: center;
      +respond-on(990px)
        flex-wrap: wrap;
      .recommended-products
        list-style: none;
        width: calc(100% - 480px);
        padding: 0
        margin: 0
        padding-right: 40px
        +respond-on(990px)
          padding-right: 0
          width: 100%;
        li
          background: clr-white
          margin: 0 0 15px
          min-height: calc(100% - 18px)
        a
          display: flex;
          padding: 20px 40px
          +respond-on(600px)
            flex-wrap: wrap;
            padding: 20px
        .image
          max-width: 152px
          padding-right: 20px
          flex-grow: 1
          +respond-on(600px)
            max-width: 100%
            padding-right: 0
            padding-bottom: 20px
          img
            display: block;
        .info
          h3
            font-size: 32px;
            color: clr-red
            margin: 0 0 15px
          p
            color clr-gray
            margin: 0
      .people-bought
        padding: 0 40px
        width: 480px
        +respond-on(990px)
          width: 100%
          padding-top: 40px
        h3
          font-size: 32px;
          color: clr-red
          margin: 0 0 15px
          padding: 0 10px
          text-align: center;
        .people-bought-slider
          max-width: 100%
          list-style: none;
          margin: 0
          padding: 0
          .slick-prev, .slick-next
            top: calc(50% - 35px)
          .slick-prev:before, .slick-next:before
            content: ""
            border-top 1px solid gray
            border-left 1px solid gray
            width: 20px
            height: 20px
            display: inline-block;
            transform: rotate(-45deg);
          .slick-next:before
            transform: rotate(135deg);
        li
          padding: 0 10px
          a
            color: clr-gray
            font-size: 13px
