#pardot {
  padding-top: 110px;
  h1 {
    text-align: center;
    color: clr-red;
    font-size: 70px;
    margin-bottom: 40px;
    @media (max-width: 800px) {
      font-size: 40px;
      padding: 0 25px;
    }
  }
  .backdrop {
    background-image: url("images/partner-resources.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 0;
    width: 100%;
    padding: 8%
    position: relative;
    @media (max-width: 800px) {
      background-size: 150%;
    }
    .card {
      background: clr-white;
      text-align: center;
      padding: 80px;
      z-index: 99;
      position: relative;
      @media (max-width: 1200px) {
        padding: 50px;
      }
      @media (max-width: 800px) {
        padding: 40px 20px;
      }
      .success, .error {
        width: 100%;
        padding 8px;
        margin-bottom: 10px;
        font-size: 18px;
        @media (max-width: 1200px) {
          font-size: 16px;
        }
        @media (max-width: 800px) {
          font-size: 14px;
        }
      }
      .error {
        border: solid 2px clr-red;
        color: clr-red;
      }
      .success {
        color: clr-success;
        border: solid 2px clr-success;
      }
      p {
        margin: 0;
        font-size: 18px;
        @media (max-width: 1200px) {
          font-size: 16px;
        }
        @media (max-width: 800px) {
          font-size: 14px;
        }
      }
      h2 {
        font-size: 28px;
        @media (max-width: 1200px) {
          font-size: 24px;
        }
        @media (max-width: 800px) {
          font-size: 18px;
        }
      }
      form {
        margin-top: 70px;
        padding: 0 15px;
        @media (max-width: 800px) {
          margin-top: 25px;
        }
        .flex {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &>div {
            width: calc(50% - 10px);
            @media (max-width: 800px) {
              width: 100%;
            }
          }
        }
        .selectric-wrapper {
          margin: 7px 0;
          &:first-child {
            margin-top: 7px;
          }
          &.selectric-open .selectric .button {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid transparent;
            border-bottom: 8px solid clr-gray;
            top: 9px;
          }
          .selectric {
            height: 45px;
            border-top: 1.5px solid clr-gray;
            border-bottom: 1.5px solid clr-gray;
            border-left: 5px solid clr-gray;
            border-right: 5px solid clr-gray;
            background: none;
            color: clr-gray;
            width: 100%;
            padding: 2px 15px;
            font-size: 16px;
            .button {
              background: none;
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid clr-gray;
              border-bottom: 8px solid transparent;
              position: absolute;
              top: 19px;
              right: 12px;
              &::before {
                display: none;
              }
            }
          }
          .selectric-items {
            border-top: 1.5px solid clr-gray;
            border-bottom: 1.5px solid clr-gray;
            border-left: 5px solid clr-gray;
            border-right: 5px solid clr-gray;
            li {
              background: clr-white;
              &:hover {
                background: clr-light-gray;
              }
            }
            li:nth-child(even) {
              background: clr-light-gray;
              &:hover {
                background: clr-light-gray;
              }
            }
          }
        }
        .input-wrapper {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 21px;
            left: 4.5px;
            width: 0;
            height: 0;
            border-left: 8px solid clr-gray;
            border-right: 8px solid transparent;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
          }
          input {
            height: 45px;
            border-top: 1.5px solid clr-gray;
            border-bottom: 1.5px solid clr-gray;
            border-left: 5px solid clr-gray;
            border-right: 5px solid clr-gray;
            background: none;
            color: clr-black;
            width: 100%;
            margin: 7px 0;
            padding: 0 15px;
            font-size: 16px;
            &::placeholder {
              color: clr-gray;
            }
          }
        }
        .btn {
          margin-top: 50px;
          padding: 18px 60px;
          color: inherit;
          font-size: 14px;
          &:hover {
            color: clr-white;
          }
        }
      }
    }
    &::after {
      content: "";
      display: block;
      background: clr-red;
      width: 100%;
      height: 50%;
      min-height: calc(100% - 61vw);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}