.woocommerce-account .addresses .title:after {
    border-top: 3px double clr-light-gray;
    padding: 4px 0 0;
}

.woocommerce-account .addresses .title:after,
.woocommerce-account .addresses .title:before {
    content: " ";
    display: table;
}
.woocommerce-account .addresses .title h3 { float: left }
.woocommerce-account .addresses .title .edit,
.woocommerce-account ul.digital-downloads li .count { float: right }
.woocommerce-account ol.commentlist.notes li.note p.meta {
    font-weight: 700;
    margin-bottom: 0;
}
.woocommerce-account ol.commentlist.notes li.note .description p:last-child { margin-bottom: 0 }
.woocommerce-account ul.digital-downloads {
    margin-left: 0;
    padding-left: 0;
}
.woocommerce-account ul.digital-downloads li {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}
.woocommerce-account ul.digital-downloads li:before {
    font-family: WooCommerce;
    speak: none;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-right: .618em;
    content: "";
    text-decoration: none;
}