.image-overlap {
  margin: 50px 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  &.reversed {
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    .image {
      left: calc(52% - 160px);
      background-position: left center;
    }
    .description {
      padding: 100px 230px 100px 80px;
      @media (max-width: 1200px) {
        padding: 25px;
      }
    }
  }
  &.red {
    color: clr-white;
    .description {
      background: clr-red;
      .btn:hover {
        color: clr-gray;
      }
    }
  }
  .image {
    height: calc(100% - 200px);
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    position: absolute;
    right: calc(52% - 160px);
    top: 50%;
    transform: translateY(-50%);
    width: calc(48% + 100px);
    @media (max-width: 1200px) {
      width: 100%;
      position: static;
      height: 300px;
      transform: none;
    }
    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
  .description {
    background: clr-light-gray;
    width: 52%;
    padding: 100px 80px 100px 230px;
    min-height: 725px;
    @media (max-width: 1200px) {
      padding: 25px;
      width: 100%;
      min-height: 0;
    }
    h2 {
      margin: 0;
      margin-bottom: 30px;
      font-size: 42px;
      @media (max-width: 1200px) {
        font-size: 30px;
      }
    }
    p {
      line-height: 125%;
      letter-spacing: .5px;
    }
    .btn {
      margin-top: 50px;
      color: inherit;
      @media (max-width: 1200px) {
        margin-top: 20px;
      }
      &.inverted-ghost:hover {
        color: clr-white
      }
      &.ghost:hover {
        color: clr-red
      }
    } 
  }
}