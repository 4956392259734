.hero-small
  background: url("images/banner.jpg")
  background-size: cover
  color: clr-white
  .wrapper
    padding: 115px 0 25px 0
    text-align: center
    @media (max-width: 1200px)
      padding: 0
      height: 115px
    h1
      letter-spacing: 2.7px
      font-size: 70px
      @media (max-width: 1200px)
        display: none


// general page hero section
.page-hero
  background-repeat: no-repeat
  background-size: cover
  background-position: center center

.page-hero-small
  padding: 85px 0 30px 0
  min-height: 292px
  position: relative
  +respond-on(600px)
    padding: 90px 20px 45px
    min-height: auto
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: clr-red
    z-index: 0
    opacity: 0.8
  .wrapper
    position: relative
    z-index: 1
  h1
    color: clr-white
    text-align: center
    font-size: 72px
    +respond-on(600px)
      line-height: 118%
      letter-spacing: 1.9px
      margin-bottom: 0px
      font-size: 40px

.page-hero-large
  color: clr-white
  position: relative
  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: clr-red
    z-index: 0
    opacity: 0.8
  .wrapper
    padding: 138px 0 80px 130px
    @media (max-width: 1200px)
      padding: 100px 25px 50px 25px
    +respond-on(600px)
      padding: 116px 20px 38px
    h1
      font-size: 92px
      letter-spacing: 4.5px
      max-width: 50%
      margin-bottom: 38px
      @media (max-width: 1000px)
        font-size: 70px
      +respond-on(600px)
        line-height: 118%
        letter-spacing: 1.9px
        margin-bottom: 26px
        font-size: 40px
    .sub-heading
      font-size: 22px
      letter-spacing: 1px
      font-weight: 400
      margin-bottom: 40px
      @media (max-width: 1200px)
        font-size: 18px
        letter-spacing: 0
      +respond-on(600px)
        font-size: 16px


// product catalog page hero section
#product-catalog-hero
  background-image: url("images/hero-pipes.jpg")


// scholarship page hero section
#scholarship-hero
  background-image: url("images/hero-scholarship.jpg")


// logo page hero section
#logo-hero
  background-image: url("images/hero-logo.jpg")
