#blog-article, #blog-archive
  .sidebar, aside#secondary
    width: 275px
    margin: 25px 0 25px 25px
    flex-shrink: 0
    @media (max-width: 1200px)
      display: none
    .search {
      margin-bottom: 25px;
      width: 100%;
      border: solid 1.5px clr-lighter-gray;
      padding: 10px 15px;
      font-size: 14px;
      i {
        font-size: 20px;
      }
      input {
        border: none;
        background: none;
        width: 150px;
        &:focus {
          outline: none;
        }
      }
      button {
        background: none;
        border: none;
        display: inline;
        margin: 0;
        padding: 0;
        color: inherit;
        cursor: pointer;
        float: right;
      }
    }
    .news-letter-form
      background: clr-white
      padding: 25px
      h2
        margin: 0
        margin-bottom: 20px
        line-height: 125%
      input
        width: 100%
        border: solid 2px clr-lighter-gray
        padding: 10px 15px
        margin: 8px 0
      .btn
        margin-top: 20px
        padding: 15px 25px
        color: clr-gray
    .popular-posts {
      background: clr-white;
      padding: 25px;
      margin-top: 25px;
      h2 {
        color: clr-red;
        margin: 0;
        margin-bottom: 30px;
        line-height: 125%;
      }
      ul {
        padding: 0;
      }
      li {
        list-style: none;
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid clr-lighter-gray;
        &:last-of-type {
          border-bottom: none;
        }
        .wpp-post-title {
          display: block;
          font-size: 18px;
          margin-bottom: 5px;
          line-height: 125%;
          text-transform: uppercase;
          letter-spacing: 2px;
          color: inherit;
          font-family: 'Oswald',sans-serif;
        }
        .wpp-author a {
          color: inherit;
        }
      }
    }
