.custom-solution
  .wrapper
    display: flex
    margin: 50px auto
    position: relative
    +respond-on(600px)
      height: 212px
      display: flex
      margin: -5px 0 0
      margin: 0
      padding: 0
  .image-wrapper
    overflow: hidden
    height: 667px
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    +respond-on(600px)
      height: 100%
    &:first
      margin-right: 40px
  .image-left
    width: 35%
  .image-right
    width: 75%
  .break
    width: 40px
  .card
    position: absolute
    background: clr-white
    color: clr-red
    padding: 55px
    top: 55%
    transform: translateY(-50%)
    left: 17%
    width: 410px
    @media (max-width: 850px)
      left: 130px
    +respond-on(600px)
      left: 17%
      padding: 21px 12px
      max-width: 42%
      top: 50%
    h3
      font-size: 40px
      line-height: 110%
      margin: 0 0 20px 0
      +respond-on(600px)
        font-size: 16px
        margin: 0
        max-width: 83%
        line-height: 130%
        letter-spacing: 0
    .btn
      +respond-on(600px)
        letter-spacing: 0.4px
        padding: 10px 20px;
