#masthead
  position: fixed
  top: 0
  width: 100%
  color: clr-white
  z-index: 100
  &.scrolled
    color: clr-gray
    .main
      background: clr-white
      border-bottom: clr-light-gray solid 1px
    .top-bar
      background: clr-light-gray
      .left
        display: flex
        justify-content: space-between
        width: 100%
        .phone a, .icons a span
          color: clr-gray
        .search input
          border-color: clr-gray !important;
          color: clr-gray !important;
          &::placeholder
            color: clr-gray !important;
            opacity: .4;
          &:-ms-input-placeholder
            color: clr-gray !important;
            opacity: .4;
          &::-ms-input-placeholder
            color: clr-gray !important;
            opacity: .4;
      .right
        display: none
    #header-menu a
      color: clr-gray
  &.light-bg, &.hovered-sub
    #header-menu li a
      color: clr-gray
    .main
      border-bottom: clr-light-gray solid 1px
      i
        color: clr-gray
  .top-bar
    background: clr-gray
    padding: 8px 10px
    transition: all .5s
    .inner
      display: flex
      justify-content: space-between
      align-items: center
      overflow-y: visible;
      .right, .right a
        color: clr-light-gray
        @media (max-width: 1200px)
          display: none
      .left
        @media (max-width: 1200px)
          display: flex
          justify-content: space-between
          width: 100%
        .phone
          @media (max-width: 1200px)
            display: flex;
            align-items: center;
          @media (max-width: 430px)
            &.hidden
              opacity: 0;
              width: 0;
              height: 0
          i
            margin-right: 0px
          a
            color: inherit
            transition: opacity 0.3s ease
            &:hover
              opacity: 0.6
        .login
          @media (max-width: 1200px)
            display: none
        .icons
          a
            color: clr-white
            transition: opacity 0.3s ease
            position: relative;
            &:hover
              opacity: 0.6
          span
            margin-left: 30px
          .search
            position: relative;
            form
              display: inline;
            input
              border-radius: 100px;
              background: none;
              border: solid 1.5px clr-white;
              height: 30px;
              color: clr-white;
              width: 0;
              padding-right: 35px;
              opacity: 0;
              transition: all .4s;
              cursor: pointer;
              &::placeholder
                color: clr-white;
                opacity: .4;
              &:-ms-input-placeholder
                color: clr-white;
                opacity: .4;
              &::-ms-input-placeholder
                color: clr-white;
                opacity: .4;
              &.active
                cursor: auto;
                opacity: 1;
                padding: 0 30px 0 12px;
                width: 175px;
            .material-icons
              position: absolute;
              right: 15px;
              top: 1px;
              cursor: pointer;
          .cart-number
            display: inline-block;
            background-color: clr-red
            color: clr-white
            font-size: 9px
            font-weight: 700
            padding-top: 1px
            width: 15px
            height: 15px
            border-radius: 50%
            text-align: center;
            position: absolute;
            top -6px
            right: 0
            margin: 0
  .main
    padding: 28px 7px
    transition: all .5s
    @media (max-width: 768px)
      padding: 15px 7px
    +respond-on(768px)
      #open-menu
        font-size: 42px
        margin-right: -3px
    +respond-on(375px)
      padding: 15px 18px
    .inner
      display: flex
      justify-content: space-between
      align-items: center
      .login a
        display: none
        @media (max-width: 768px)
          display: block
          color: clr-gray
          padding: 10px 30px 10px 15px
          font-size: 20px
      .logo
        width: 204px
        display: block
      i
        display: none
      @media (max-width: 768px)
        i
          display: block
          font-size: 30px
          margin-right: 8px
          cursor: pointer
      .items
        @media (max-width: 768px)
          position: fixed
          top: 0
          right: 0
          background: clr-white
          color: clr-gray
          padding: 20px 0
          height: 100vh
          border-left: clr-light-gray solid 1px
          width: 30vw
          min-width: 200px
          overflow: hidden
          transform: translateX(100%)
          transition: transform 0.4s ease
          overflow: auto
          &.active
            transform: translateX(0)
            transition: transform 0.4s ease
      .items .close
        display: none
        @media (max-width: 768px)
          display: block
          position: absolute
          cursor: pointer
          top: 10px
          right: 5px

#header-menu
  margin: 0
  padding: 0
  @media (max-width: 768px)
    padding-top: 20px
  ul
    list-style-type: none
    margin: 0
    padding: 0
  &.menu>li
    display: inline-block
    margin-left: 60px
    @media (max-width: 950px)
      margin-left: 30px
      font-size: 14px
    @media (max-width: 768px)
      margin-left: 0
      display: block
      padding: 10px 30px 10px 15px
      font-size: 20px
      position: relative
      .arrow
        cursor: pointer
        content: ""
        position: absolute
        width: 10px
        height: 10px
        top: 15px
        right: 15px
        border-top: 1.5px solid clr-gray
        border-left: 1.5px solid clr-gray
        transform: rotate(225deg)
        &.flipped
          transform: rotate(45deg)
          top: 20px
      &>.sub-menu
        text-transform: none
        letter-spacing: 1px
        font-size: 18px
        padding-left: 8px
        display: none
        &>li
          margin-top: 15px
          a
            color: clr-gray !important
          &>ul
            border-top: 1.5px solid clr-light-gray
            padding-top: 5px
            margin-top: 5px
          li
            font-weight: 300
            margin-bottom: 2px
    @media (min-width: 769px)
      &>.sub-menu
        display: none
        text-transform: none
        letter-spacing: 1px
        position: fixed
        width: 100%
        left: 0
        top: 0
        background: clr-white
        z-index: -1
        padding: 150px calc((100% - 1250px) / 2) 25px calc((100% - 1250px) / 2)
        border-bottom: clr-light-gray solid 1px
        column-count: 4
        column-gap: 30px
        max-height: 100vh
        @media (max-width: 1300px)
          padding: 150px 20px 25px 20px
        &>li
          margin: 0
          margin-bottom: 20px
          break-inside: avoid-column
          page-break-inside: avoid
          -webkit-column-break-inside: avoid
          overflow: hidden
          a
            color: clr-gray !important
          &>ul
            border-top: 1.5px solid clr-light-gray
            padding-top: 8px
            margin-top: 8px
          li
            font-weight: 300
    a
      color: clr-white
      @media (max-width: 768px)
        color: clr-gray
