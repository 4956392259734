#learn {
  padding-top: 165px;
  h1 {
    color: clr-red;
    text-align: center;
    font-size: 55px;
    margin-bottom: 65px;
    +respond-on(800px) {
      font-size: 8vw;
    }
  }
  .question {
    margin: 1px 35px;
    +respond-on(1200px) {
      margin: 1px 5px;
    }
    &:last-of-type {
      margin-bottom: 65px;
    }
    &.selected {
      h2 {
        background-image: url('images/icons/up.svg');
      }
    }
    h2 {
      background-color: clr-gray;
      background-image: url('images/icons/down.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 24px) 50%;
      background-size: 45px;
      color: clr-white;
      margin: 0;
      padding: 12px 70px 12px 24px;
      font-size: 30px;
      cursor: pointer;
      +respond-on(800px) {
        font-size: 24px;
        padding: 12px;
        padding-right: 57px;
        background-position: calc(100% - 12px) 50%;
      }
    }
    p {
      display: none;
      margin: 0;
      font-size: 28px;
      font-weight: 300;
      line-spacing: 125%;
      padding: 15px 0;
      color: clr-darker-gray;
      +respond-on(800px) {
        font-size: 20px;
      }
    }
  }
}