
.woocommerce-cart   
    // reset woocommerce display
    .woocommerce
        display: block

.cart-content-section 
    padding: 50px 0

    .woocommerce-message, .woocommerce-info, .woocommerce-error, .woocommerce-noreviews, p.no-comments
        width: 100%
        padding: 20px
        color: clr-white

        a     
            display: inline-block
            color: clr-white
            margin-left: 15px
            text-decoration: underline

.return-to-shop  
    margin-left: 20px

/**
 * Product list table
 */

.woocommerce-cart-form 
    padding: 50px 0

    table 
        border: 1px solid clr-light-gray
        border-width: 1px 0px 1px 0px;
        border-radius: 2px
        background-color: clr-white
        width: 100%
        text-align: left
        border-collapse: collapse

    th, td   
        padding: 20px
        border-bottom: 1px solid clr-light-gray

    th 
        background-color: clr-white
        font-weight: 700
        font-size: 18px
        text-transform: uppercase;

    td    
        font-size: 16px
        a    
            color: clr-red
            font-weight: 700

    .product-remove 
        a    
            font-size: 24px

    .product-thumbnail
        width: 30%
        padding: 10px;
        @media (max-width: 767px)
            width: 100%
        img   
            width: 100%
            max-width: 100%
            height: auto

    .product-name
        width: 40%
        padding: 10px 20px 0 0;
        @media (max-width: 767px)
            width: 100%

    // .product-price

    .product-quantity 
        .qty
            width: auto;
            padding: 5px;
            text-align: left;
            display: inline-block;
            padding: 0px 20px 0px 0px;
            background-position: right;

        input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button 
            -webkit-appearance: none
            margin: 0
        
        input[type="number"] 
            -moz-appearance: textfield
    
    // .product-subtotal

    .coupon 
        display: inline-block
        @media (max-width: 767px)
            display: block
        label 
            @extend $sr-only
        .input-text
            padding: 12px 8px
            margin-right: 15px
            @media (max-width: 767px)
                margin-bottom: 15px
            @media (max-width: 380px)
                display: block
                width: 100%
                margin-right: 0
                margin-bottom: 15px
        .button
            @media (max-width: 380px)
                margin-bottom: 15px

    .button
        @extend .btn.inverted-ghost
        padding: 13px 20px
        font-size: 14px
        letter-spacing: 0
        font-weight: 700
        float: right 
        cursor: pointer  
        border-color: clr-light-gray
        color: clr-gray

        &:hover, &:active, &:focus
            border-color: clr-red

        @media (max-width: 767px)
            float: none

// remove product thumbnail data title attr
.shop_table_responsive tr .product-thumbnail:before
    display: none


/**
 * Cart total
 */

.cart-collaterals
    @extend $clearfix

.cart_totals
    float: right

    table 
        border-radius: 2px
        background-color: clr-white
        text-align: left
        border-collapse: collapse
        margin-bottom: 30px
        width: 100%

    th, td 
        padding: 10px 0

    td   
        text-align: right

.wc-proceed-to-checkout
    margin-bottom: 80px
    .checkout-button
        @extend .btn
        padding: 20px 40px

/**
 * Responsive woocommerce table
 */

@media only screen and ( max-width: 479px ) {
	.short-description, .product_meta, body.woocommerce div.product .woocommerce-tabs, body.woocommerce #content div.product .woocommerce-tabs { display: none; }
	body.woocommerce .images { float: none !important; width: auto !important; margin-bottom: 40px !important; clear: both !important; }

	table .product-thumbnail { display: none; }

	.woocommerce-page #content div.product form.cart .variations { margin-left: 0; }

	table.cart th, #content table.cart th, table.cart td, #content table.cart td, table.cart tr, #content table.cart tr, #content-area table tr, #content-area table td, #content-area table th { padding: .857em 0.287em; }

	.woocommerce .woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1, .woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 { width: 100% !important; }
	.woocommerce .woocommerce form .form-row, .woocommerce-page form .form-row { width: auto !important; float: none !important; }

	#order_review .shop_table { margin-left: 0; }
}




