// body
//   padding-top: 62px
//   background-color: body-background-gray
//
// .container
//   max-width: 1440px
//   margin: 0 auto
//   padding: 0 130px
//   +respond-on(1150px)
//     padding: 0 60px
//   +respond-on(550px)
//     padding: 0 30px
//   +respond-on(375px)
//     padding: 0 20px
//   &.full-width
//     padding: 0
//     max-width: inherit;
// a
//   &.underline
//     text-decoration: underline;
//
//
// .hide-on-desktop
//   display: none;
//   +respond-below(768px)
//     display: inherit;
// .hide-on-mobile
//   display: none;
//   +respond-below(768px)
//     display: inherit;
//
//
// input
//   -webkit-appearance: none;
//
//
// #audioPlayer
//   display: none
body
  padding-top: 0 !important

.woocommerce-cart .wrapper,
.woocommerce-checkout .wrapper
  max-width: 1100px;

.wrapper
  max-width: 1290px
  margin: auto
  padding: 0 20px
  @media (max-width: 1200px)
    width: 100%
    overflow-y: hidden
    padding: 0 5px
i
  vertical-align: text-bottom
img
  max-width: 100%


#page
  width: 100%
