.product-detail
    padding: 50px 0;
    background-color: clr-light-gray

    h2
        @extend $reset-header;
        font-family: 'Oswald', sans-serif;
        color: clr-red;
        font-size: 67px;
        font-weight: 400;
        margin-bottom: 30px;

.product-detail-list
    @extend $clearfix;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;

.product-detail-list-item
    display: inline-block;
    width: 33.33%;
    position: relative;
    font-size: 16px;
    // margin-bottom: 50px;
    vertical-align: top;

    padding-bottom: 60px
    h3
      color: clr-red
      font-size: 24px
      margin-bottom: 60px

.product-detail-img
    background-color: clr-black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 230px;
    z-index: 0;

.product-detail-text
    position: relative;
    background-color: clr-white;
    padding: 50px;
    width: 70%;
    margin: 80px auto 0 auto;
    z-index: 1;
    text-align: center;
    img
        width: auto;
        height: 60px;
        display: inline-block;

.product-detail-more-info
    top: inherit;
    margin-bottom: inherit;
    background: clr-red
    padding: 30px 30px 100px
    +respond-on(620px)
      padding: 30px 20px 100px
      width: 80%;
    h3
      color: clr-white
      font-size: 43px
      text-align: left;
      margin-bottom: 50px
      +respond-on(1370px)
        font-size: 36px
      +respond-on(822px)
        font-size: 32px
      +respond-on(620px)
        font-size: 30px
    .phone-link
      color: clr-white
      margin-bottom: 90px
      font-size: 38px
      +respond-on(1370px)
        font-size: 33px
      +respond-on(822px)
        font-size: 27px
      +respond-on(620px)
        font-size: 25px
      i
        font-size: 42px
        top -6px
        display: inline-block;
        position: relative
        +respond-on(1370px)
          font-size: 34px
        +respond-on(822px)
          font-size: 28px
        +respond-on(620px)
          font-size: 26px
    .btn
      position: absolute;
      width: calc(100% - 60px)
      left: 30px
      bottom: 40px
