#custom-subpage
  .hero
    padding: 160px 0 50px
    background: clr-white
    @media (max-width: 1200px)
      padding: 90px 35px 15px
    .wrapper
      display: flex
      flex-wrap: wrap-reverse
    .content
      padding-right: 30px
      width: 40%
      @media (max-width: 1200px)
        width: 100%
        padding-top: 10px
      h1
        margin: 0
        margin-bottom: 15px
        font-size: 40px
        padding-top: 10px
        color: clr-red
        @media (max-width: 1200px)
          font-size: 40px
      p
        line-height: 125%
      .btn
        margin: 30px 0
        padding: 25px 60px
        color: inherit
        &:hover
          color: clr-white
    .video, .image
      margin-top: 15px
      width: 60%
      flex-shrink: 0
      @media (max-width: 1200px)
        width: 100%
      .iframe-wrapper
        position: relative
        padding-bottom: 56.25%
        /* 16:9 */
        iframe
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%

  .breadcrumb
    background: clr-light-gray;

  .features
    text-align: center
    display: block;
    h2 {
      color: clr-red;
      text-align: left;
      font-size: 65px;
      padding-top: 45px
      margin: 0 0 45px;
      @media (max-width: 1200px) {
        font-size: 40px;
      }
    }
