.product-hero
    padding: 150px 0 100px 0;
    background-color: clr-red;

    .product-content-container 
        @extend $clearfix;


.product-hero-text
    float: left;
    width: 48%;

    h1
        @extend $reset-header;
        font-family: 'Oswald', sans-serif;
        color: clr-white;
        font-size: 42px;
        font-weight: 400;
        margin-bottom: 20px;

    p 
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: clr-white;
        margin-bottom: 80px;


.product-hero-media
    float: right;
    width: 48%;