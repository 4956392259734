.breadcrumb
  padding: 10px 0
  background-color: clr-white
  &.grey 
    background-color: clr-light-gray
  .breadcrumb-container
    max-width: 1250px;
    margin: auto;
    font-size: 16.5px
    @media (max-width: 1200px)
      padding-left: 10px
      font-size: 14px;
    & > span
      letter-spacing: .5px
      margin: 0
      color: clr-gray
      @extend .oswald-font
      text-transform: uppercase;
      a
        color: clr-lighter-gray
      @media (max-width: 1200px)
        margin: 3px
        letter-spacing: 0
