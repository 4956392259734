.clear:before,
.clear:after {
    content:' ';
    display:table;
}

.clear:after {
    clear:both;
}
.clear {
    *zoom:1;
}
img {
	max-width:100%;
}

a:focus {
	outline:0;
}
a:hover,
a:active {
	outline:0;
}

.wrapper {
	position:relative;
}

/*------------------------------------*\
    WORDPRESS CORE
\*------------------------------------*/

.alignnone {
	margin:5px 20px 20px 0;
}
.aligncenter, div.aligncenter {
	display:block;
	margin:5px auto 5px auto;
}
.alignright {
	float:right;
	margin:5px 0 20px 20px;
}
.alignleft {
	float:left;
	margin:5px 20px 20px 0;
}
a img.alignright {
	float:right;
	margin:5px 0 20px 20px;
}
a img.alignnone {
	margin:5px 20px 20px 0;
}
a img.alignleft {
	float:left;
	margin:5px 20px 20px 0;
}
a img.aligncenter {
	display:block;
	margin-left:auto;
	margin-right:auto;
}
.wp-caption {
	background:#FFF;
	border:1px solid #F0F0F0;
	max-width:96%;
	padding:5px 3px 10px;
	text-align:center;
}
.wp-caption.alignnone {
	margin:5px 20px 20px 0;
}
.wp-caption.alignleft {
	margin:5px 20px 20px 0;
}
.wp-caption.alignright {
	margin:5px 0 20px 20px;
}
.wp-caption img {
	border:0 none;
	height:auto;
	margin:0;
	max-width:98.5%;
	padding:0;
	width:auto;
}
.wp-caption .wp-caption-text, .gallery-caption {
	font-size:11px;
	line-height:17px;
	margin:0;
	padding:0 4px 5px;
}
.sticky {

}
.bypostauthor {

}
