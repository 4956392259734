#homepage
  .hero
    background: url("images/banner.jpg")
    background-size: cover
    color: clr-white
    .wrapper
      padding: 138px 0 80px 130px
      @media (max-width: 1200px)
        padding: 100px 25px 50px 25px
      +respond-on(600px)
        padding: 116px 40px 38px
      h1
        font-size: 92px
        letter-spacing: 4.5px
        max-width: 50%
        margin-bottom: 38px
        @media (max-width: 1200px)
          font-size: 50px
        +respond-on(600px)
          line-height: 118%
          letter-spacing: 1.9px
          margin-bottom: 26px
          font-size: 40px
      .sub-heading
        font-size: 22px
        letter-spacing: 1px
        font-weight: 400
        margin-bottom: 40px
        @media (max-width: 1200px)
          font-size: 18px
          letter-spacing: 0
        +respond-on(600px)
          font-size: 16px
          margin-bottom: 48px
  .features
    text-align: center
    .btn
      position: relative
      top: -34px
      +respond-on(1200px)
        top: -23px

  .brands
    .title
      color: clr-red
      font-size: 70px
      line-height: 110%
      margin-top: 45px
      margin-bottom: 30px
      max-width: 50%;
      @media (max-width: 1200px)
        font-size: 40px
        margin-left: 10px
    .logo-slider
      max-width: 100%
      overflow: hidden
      .slide
        height: 100px
        display: flex !important
        align-items: center
        justify-content: center
        img
          max-height: 100%
          max-width: 70%
          &:focus
            outline: none
    .intro-video
      @media (min-width: 1200px)
        position: relative
        margin: 25px 0
        display: flex
        justify-content: flex-end
      .video
        @media (min-width: 1200px)
          position: absolute
          right: calc(52% - 160px)
          top: 50%
          transform: translateY(-50%)
          width: calc(48% + 160px)
        @media (min-width: 1600px)
          width: 900px
        .iframe-wrapper
          position: relative
          padding-bottom: 56.25%
          /* 16:9 */
          iframe
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%


      .description
        background: clr-red
        color: clr-white
        padding: 25px
        @media (min-width: 1200px)
          width: 52%
          padding: 120px 0 120px 300px
        h1
          font-size: 40px
          margin-top: 0
          @media (min-width: 1200px)
            font-size: 42px
            max-width: 270px
        p
          line-height: 125%
          letter-spacing: .5px
          @media (min-width: 1200px)
            max-width: 270px
        .btn
          margin-top: 50px
    .awards
      display: flex
      justify-content: space-between
      margin: 50px 0
      @media (max-width: 1200px)
        margin: 15px 0
      .award
        width: 20%
        display: flex
        justify-content: center
        align-items: center
        height: 140px
        padding: 25px 10px
        @media (max-width: 1200px)
          padding: 15px 10px
        img
          max-width: 100%
          max-height: 100%

  .custom
    text-align: center
    margin-top: 50px
    h2
      background: clr-light-gray
      margin-bottom: 0
      padding: 55px 50% 78px 75px
      text-align: left
      color: clr-red
      font-size: 68px
      letter-spacing: 3.2px
      line-height: 110%
      @media (max-width: 1200px)
        padding: 35px
        font-size: 40px
      +respond-on(600px)
        padding: 35px 40px 48px
        font-size: 36px
        letter-spacing: 1.1px
    .btn
      position: relative
      top: -34px
      +respond-on(1200px)
        top: -23px
  .catalog
    display: flex
    margin: 115px auto 135px
    overflow: hidden
    position: relative
    @media (max-width: 1200px)
      margin: 25px auto
    +respond-on(600px)
      height: 676px
    img
      height: 450px
      @media (max-width: 1200px)
        height: 300px
      @media (max-width: 500px)
        &:first-of-type
          margin-right: 15px
    .center
      background: clr-red
      margin: 0 19px
      color: clr-white
      flex-grow: 1
      padding: 50px
      padding-right: 150px
      position: relative
      @media (max-width: 1200px)
        flex-shrink: 1
        padding: 25px
      +respond-on(600px)
        position: absolute
        top: 312px
        left: 0
        margin: 0
        padding: 35px 40px 55px
        width: 100%
      h1
        font-size: 42px
        margin: 0
        @media (max-width: 1200px)
          font-size: 30px
        +respond-on(600px)
          font-size: 34px
          max-width: 86%
      p
        margin: 20px 0
        +respond-on(1200px)
          margin: 20px 0 60px 0
        +respond-on(600px)
          margin: 30px 0 55px
      .btn
        position: absolute
        bottom: 62px
        @media (max-width: 1200px)
          bottom: 25px
          padding: 10px 15px
          margin-right: 25px
        @media (max-width: 500px)
          position: static
