.zoom-in
  transform: scale(1)
  transition: transform 0.2s ease
  &:hover
    transform: scale(1.02);
    transition: transform 0.2s ease

.ease-all
  transition: all 0.3s ease

.background-zoom-out
  background-size: 110%
