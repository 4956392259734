/**
 * Order tab of my-account
 */

.woocommerce-orders
    .woocommerce-button.button.view
        color: clr-red

/**
 * download tab of my-account
 */

.woocommerce-downloads
    // yes, this is a nesting hell, 
    // but it's necessary to avoid affecting other woocommerce messages
    .woocommerce-MyAccount-content
        .woocommerce-Message
            background: none
            .woocommerce-Button
                background: none  
                color: clr-red
                display: block
                padding: 0
                margin-bottom: 15px
                text-decoration: underline


/**
 * 
 */

.woocommerce-payment-methods
    .woocommerce-message, 
    .woocommerce-info, 
    .woocommerce-error
        padding: 20px
        width: 100%
        background: none
        color: inherit