body.woocommerce-page
  color: #000 !important;
.woocommerce
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  font-family: lato,sans-serif !important;
  & > h2
    flex 1 1 100%
    text-align: center;

  .woocommerce-form-login
    flex: 0 1 auto
  .woocommerce-MyAccount-navigation
    flex: 0 1 200px;
    border-right: 1px solid alpha(clr-gray, 0.6)
    +respond-on(990px)
      flex 1 1 100%
      border-right: 0
    ul
      list-style: none;
      margin: 0
      padding: 20px 30px

      li
        margin: 10px 0
        +respond-on(990px)
          text-align: center;
        a
          color: clr-gray
          display: inline-block;
          padding-left: 4px
        &.is-active, &:hover
          a
            color: clr-red
            font-weight: bold
            padding-left: 0
            transition: all 0.1s ease
  .woocommerce-message
    //  background-color: alpha(clr-red, 0.2)
    background-color: transparent
    width: 100%
    padding: 10px 20px
    border-radius: 3px
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px
    .woocommerce-Button
      display: block;
      text-decoration: none;
      color: clr-white
      background-color: clr-red
      padding: 10px
      margin: 10px auto
      max-width: 200px
  .woocommerce-MyAccount-content
    flex 1 1 calc(100% - 200px )
    padding: 20px 30px
    a:not(.button)
      color: clr-red
      text-decoration: underline;
    fieldset
      margin: 25px 0
      border-color: alpha(clr-light-gray, 0.4)
    .input-text
      padding: 4px 8px
      border: 1px solid alpha(clr-black, 0.3)
      border-radius: 2px
  .woocommerce-Button
    background-color: clr-red
    color: clr-white
    border-radius: 0
    outline: 0
    padding: 10px;
    letter-spacing: 0.8px
    cursor: pointer
    background: clr-red
    border: none
    color: clr-white
    display: inline-block;
    text-transform: uppercase;
  .woocommerce-form__label-for-checkbox
    display: block
  .woocommerce-orders-table
    width: 100%
    thead
      color: #000
      th
        padding: 9px 8px
        text-transform: uppercase;
        font-weight: normal;
    tbody
      tr

      td
        padding: 8px 8px
  .woocommerce-table
    width: 100%
    thead
      color: #000
      th
        padding: 9px 8px
        text-transform: uppercase;
        font-weight: normal;
    tbody
      tr

      td
        padding: 8px 8px
        &:first-of-type
          text-align: center;
        ul
          list-style: none;
          padding: 0
    tfoot
      color: #000
      td
        padding: 8px 8px
  .woocommerce-checkout-review-order-table
    width: 100%
    thead
      color: #000
      th
        padding: 9px 8px;
        text-transform: uppercase;
        font-weight: normal;
    tbody
      tr
      td
        padding: 8px 8px
        &:first-of-type
          padding: 0px 8px 8px;
          text-align: center;
        ul
          list-style: none;
          padding: 0
    tfoot
      color: #000
      th,td
        padding: 8px 8px
    dl.variation
      text-align: right;
      padding: 0px;
      dt
        font-weight: bold
      dd
        margin-left: 20px
    .cart_item
      & > td
        padding: 15px 8px
        &.product_qty
          width: 10%;
          text-align: left;
      .product-name
        text-align: right;

  .woocommerce-Addresses
    display: flex;
    margin: 0 -15px
    +respond-on(990px)
      flex-wrap: wrap;
    .woocommerce-Address
      flex 1 1 auto
      margin: 0 15px
      +respond-on(990px)
        flex 1 1 100%
        margin-bottom: 20px
      h3
        margin: 0
        font-size: 20px;
        font-family: lato,sans-serif !important;
    .woocommerce-Address-title
      padding-right: 20px
      &:after
        content: "";
        display: table;
        clear: both;
  .woocommerce-error
    margin: 0
    padding: 0
    list-style: none;
    width: 100%
    max-width: 700px
    padding: 20px
    border-radius: 3px
    li
      color white

body.woocommerce-cart, body.woocommerce-checkout
  .cart-container
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    form.woocommerce-checkout {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }
    .cart-extra-line {
      #pagination {
        margin: 0;
        padding: 0;
        #last, #next{
          a {
            color: #fff;
            background: #f00;
          }
        }
        li {
          text-align: center;
          margin: 0 0 10px 0;
          width: 100%;
          a {
            float: none;
            display: inline-block;
            min-width: 80%;
            background: none;
            border: 2px solid #f00;
            color: #f00;
            transition: .3s;
            padding: 10px;
            text-transform: uppercase;
          }
        }
      }
    }
    .cart-top-page-title
      flex: 0 1 100%;
    .cart-left
      flex: 0 1 100%;
      @media (min-width: 800px)
        flex: 0 1 73%;
      dl.variation {
        display: flex;
        flex-wrap: wrap;
        dd {
          flex: 1 1 100%;
          margin: 0;
          padding: 0px;
          margin-bottom: 3px;
          p {
            margin: 0;
            padding: 0px;
          }
        }
        dt {
          display: none;
          flex: 1 1 30%;
          margin: 0;
          padding: 0px;
          margin-bottom: 3px;
        }
      }
      .custom-review-shower{
        text-align: center;
        padding: 30px 0px;
        .widget{
          display: inline-block;
        }
      }
      .cross-sells{
        border: 1px solid;
        position: relative;
        margin-top: 30px;
        h2{
          display: inline-block;
          padding: 0px 20px;
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          z-index: 10;
          top: -36px;
          background: #fff;
        }
        .product-catalog-text{
          text-align: center;
          padding: 30px 0px;
        }
        .product-catalog-text h2{
          position: unset;
          top: unset;
          left: unset;
          padding: 0px;
          background: transparent;
          transform: unset;
          color: black;
        }
        ul.products{
          margin-top: 20px;
          li{
            width: calc(100% / 4 - 0px);
            display: inline-block;
            margin: 0;
            padding: 10px;
            a.woocommerce-LoopProduct-link {
              width: unset;
              padding-right: unset;
              margin-right: unset;
              border-right: unset;
              flex-shrink: unset;
            }
            .price, .product-button-wrapper{
              display: none;
            }
          }
        }
      }
    .cart-right
      flex: 0 1 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: start;
      margin-top: 40px;
      @media (min-width: 800px)
        margin-top: 0px;
        flex: 0 1 23%;
      .cart-side-block
        margin-bottom: 10px;
        flex: 0 1 100%;
        border: 1px solid black;
      .cart-right-sidebar-1.cart-side-block, cart-right-sidebar-2.cart-side-block{
        border: 0px;
      }
      .cart-side-block:nth-child(1){
        order: 3;
        @media (min-width: 800px){
          order: 0;
        }
      }
      .cart-side-block:nth-child(2){
        order: 4;
        @media (min-width: 800px){
          order: 0;
        }
      }
      .cart-side-block:nth-child(3){
        order: 2;
        @media (min-width: 800px){
          order: 0;
        }
      }
      .cart-side-block:nth-child(4){
        order: 1;
        @media (min-width: 800px){
          order: 0;
        }
      }
      .cart-side-block:nth-child(5){
        order: 5;
        @media (min-width: 800px){
          order: 0;
        }
      }
      .cart-right-sidebar-2{
        background: #E6E7E8;
        position: relative;
        h3{
          margin: 10px 10px 0 10px;
          padding: 0 0 10px 0;
          font-size: 20px;
          font-family: lato,sans-serif !important;
          span:not(.h3Row):not(.icon-img){
            margin-top: 5px;
            display: block;
            i{
              margin-right: 10px;
              color: clr-red;
            }
          }
        }
        .cart-side-question{
          background: #fff;
          padding: 10px;
        }
      }
      .cart-right-sidebar-1{
        .widget_media_image{
          text-align: center;
          margin-bottom: 20px;
          img {
            height: 40px;
          }
        }
        .cart-right-promo {
          background: #E6E7E8
          position: relative;
          h3{
            margin: 0;
            padding: 10px;
            font-size: 20px;
            font-family: lato,sans-serif !important;
          }
          .plus-minus{
            position: absolute;
            right: 10px;
            top: 10px;
            span.fa-minus{
              display: none;
            }
          }
          .coupon{
            padding: 10px;
            width: 100%;
            display: none;
            display: none;
            button, input {
              padding: 12px 8px;
              margin-right: 0px;
              width: 100%;
              float: none;
              padding: 10px;
              width: 80%;
              text-transform: uppercase;
            }
            button{
              margin-top: 10px;
            }
          }
        }
      }
      .cart-right-note{
        background: #E6E7E8;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
        p{
          margin: 0;
          padding: 0;
        }
        label {
          background: #E6E7E8;
          width: 100%;
          display: block;
          text-transform: uppercase;
          font-weight: normal;
          font-style: normal;
          font-family: 'Oswald',sans-serif;
          font-size: 20px;
          line-height: inherit;
          padding: 10px 0 10px 10px;
        }
        .woocommerce-input-wrapper{
          display: inline-block;
          width: 100%;
          margin: 0;
          padding: 0;
          textarea {
            margin: 0;
            padding: 5px;
            width: 100%;
            height: 75px;
            resize: none;
          }
        }
      }
      .cart-right-summary
        background: #E6E7E8;
        position: relative;
        #wc-stripe-payment-request-wrapper
          display: none;
        h3
          margin: 10px 10px 0 10px;
          padding: 0 0 10px 0;
          border-bottom: 2px solid black;
          font-weight: bolder;
          font-size: 20px;
          font-family: lato,sans-serif !important;
        .cart-side-expected-total
          position: relative;
          padding: 10px;
          .summary-amount-key
            span.ss-total
              display: block;
              text-align: right;
              font-weight: bolder;
              font-size: 20px;
        .cart-side-summary-acf
          padding: 10px;
        .cart-side-summary-subtotal
          border-bottom: 2px solid black;
          padding: 10px 0px;
          position: relative;
          margin: 10px 10px 0 10px;
          padding: 0 0 10px 0;
          .ss-total
            position: absolute;
            right: 0px;
            font-weight: 700;
        .checkout-button
          float: none;
          display: inline-block;
          min-width: 80%;
          margin-top: 20px;
          padding: 10px;
        #pay_with_amazon
          float: none;
          display: inline-block;
          min-width: 80%;
          width: 60%;
          margin-top: 20px;
        .cart-side-summary-buttons
          background: #ffffff;
          padding: 10px;
          .cart-extra-line
            text-align: center;
            button
              float: none;
              display: inline-block;
              min-width: 80%;
              background: none;
              border: 2px solid #f00;
              color: #f00;
              transition: .3s;
              padding: 10px;
              text-transform: uppercase;

#bpwcmscSteps .steps {
  margin: 0px;
}
#bpwcmscSteps .steps ul {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
#bpwcmscSteps .steps ul li a{
  height: auto;
  text-align: left;
}
#bpwcmscSteps .steps ul li:first a{
  padding-left: 0px;
}
#bpwcmscSteps .steps ul li.traversed.done .checkmark,
#bpwcmscSteps .steps ul li.disabled .checkmark{
  display: none;
}
body.woocommerce-checkout {
  .woocommerce-message, .woocommerce-info, .woocommerce-error{
    display: block;
  }
  .woocommerce {
    .steps {
      margin: 0px;
      a {
        height: auto;
        text-align: left;
        .checkmark{
          display: none;
        }
      }
    }
    .tab-content{
      width: 100%;
    }
  }
  form.checkout {
    margin: 0px;
    width: 100%;
    max-width: 97%;
  }
}
.woocommerce form.checkout_coupon{
  border-radius: 0px;
  border: 0px;
  padding: 0;
  margin: 0;
  input, button{
    width: 100%;
  }
}

.woocommerce .up-sells.upsells.products{
  ul.products {
    li{
      margin-right: 0px;
      width: calc(100% / 2 - 10px);
      padding: 10px;
      &:nth-child(odd){
        margin-right: 20px
      }
      .product-button-wrapper {
        margin-top: 10px;
      }
    }
  }
}
.woocommerce
  #bpwcmscSteps
    .steps
      ul
        li
          a
            .namebox
              text-transform: uppercase;

.woocommerce .owl-carousel .owl-item{
  background: #fff;
  img {
    width: auto;
    margin: 0 auto;
  }
}
.shopping_cart_header
  font-weight: bolder;

.woocommerce-cart-form th
  text-transform: uppercase;

.woocommerce-cart-form th.product-thumbnail
  padding-left: 0px;

.woocommerce .woocommerce-checkout-review-order-table dl.variation
  text-align: left;

.cart-back-to-cart a
  border: 1px solid gray;
  padding: 10px;
  display: inline-block;
  color: black;

.product-subtotal .woocommerce-Price-amount{
  font-weight: bolder;
}

table.shop_table td{
 vertical-align: top;
 text-transform: uppercase;
 padding: 20px;
 &.product-thumbnaildd {
   padding: 0px 20px 20px;
 }
}

.product-name {
  padding: 0px;
  .variation{
    margin: 0px;
  }
}

.woocommerce-checkout-review-order-table
  #shipping_method
    li
      display: flex;
      label
        flex: 1 0 auto;
        display: flex;
        .amount
          display: inline-block;
          flex: 1 0 auto;
          justify-content: flex-end;
          align-content: flex-end;

.product_qty
  width: 10%;
  text-align: left;


table.shop_table th {
  text-transform: uppercase;
  text-align: left;
  font-weight: normal;
}
span.h3Row{
  display: table;
  .icon-img {
    vertical-align: middle;
    display: table-cell;
    padding-top: 5px;
    img {
      width: 30px;
    }
  }
  a{
    vertical-align: middle;
    display: table-cell;
    font-size: 18px;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #000;
  }
  a:visited{
    color: #000;
  }
  a:hover{
    color: #000;
  }
  a:active{
    color: #000;
  }
}
.cart-empty-row{
  margin-top: 5px;
  &:first-child{
    margin-top: 0px;
  }
  a{
    color: #000;
  }
  a:visited{
    color: #000;
  }
  a:hover{
    color: #000;
  }
  a:active{
    color: #000;
  }
}