.woocommerce // need this one to combat deep nesting from default woocommerce styles
    .woocommerce-form-login 
        .woocommerce-Button
            padding: 15px 40px
        .woocommerce-form__label-for-checkbox
            float: right
            @media screen and (max-width: 480px)
                float: none
                display: block !important
                margin: 10px 0

        .woocommerce-LostPassword
            a
                color: clr-gray