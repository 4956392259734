.site-footer
  background: clr-darker-gray
  color: clr-white
  padding: 70px 0
  @media (max-width: 1200px)
    padding: 43px 0
  .wrapper
    display: flex
    justify-content: space-between
    position: relative
    @media (max-width: 1200px)
      flex-wrap: wrap-reverse
      padding: 25px
    +respond-on(600px)
      padding: 0 67px
    +respond-on(375px)
      padding: 0 20px
    a
      color: clr-white
      text-decoration: none
      &:hover
        text-decoration: underline
    .contact-info
      +respond-on(600px)
        margin-top: 78px
      .section
        margin-bottom: 24px
        padding-left: 48px
        position: relative
        +respond-on(600px)
          padding-left: 42px
        img
          position: absolute
          height: 30px
          top: 5px
          left: 0
          &.first-icon
            top: 0
        p
          margin: 0
          line-height: 129%
          font-size: 14px
          letter-spacing: 0.7px
          &.uppercase
            text-transform: uppercase;
        .head
          font-size: 14px
          letter-spacing: 0.6px
          line-height: 144%
      .social-icons
        margin: 98px 0 55px
        @media (max-width: 1200px)
          margin-bottom: 50px
        +respond-on(600px)
          margin: 84px 0 50px
        img
          height: 15px
          display: inline-block
          margin-right: 26px
          +respond-on(600px)
            margin-right: 23px
    .mailing-list
      width: 278px
      p
        margin: 0 0 17px 0
      h3
        font-size: 22px
        letter-spacing: 1.1px
        margin: -1px 0 10px 0
      input
        background: none
        border: 2px solid clr-black
        color: clr-white
        padding: 8px 10px
        width: 100%
        margin: 5px 0
        font-size: 12px
        &::placeholder
          color: clr-lighter-gray
        &:focus
          outline: none
      .btn
        margin-top: 16px
        padding: 15px 30px
  .copyright
    font-size: 21px
    +respond-on(600px)
      font-size: 14px
      p
        margin: 5px 0
