.navigation
  &.pagination
    display: flex
    align-items: center
    padding: 10px 0
    max-width: 1250px;
    margin: auto;
    h2
      display: none;
    a
      color: clr-gray
    .page-numbers
      padding: 0 4px
      text-decoration: none
      &.current
        color: clr-red
      @media (max-width: 1200px)
        padding: 0 2px
    @media (max-width: 1200px)
      justify-content: center
      padding: 5px
