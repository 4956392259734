.four-square
  margin: 25px 0
  max-width: 100vw
  overflow: hidden
  &.grey
    .text, .test-results .heading
      background-color: clr-light-gray
      color: inherit
    .btn
      color: inherit
      &.inverted-ghost:hover
        color: clr-white
      &.ghost:hover
        color: clr-red
  .wrapper
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    &>div
      width: calc(50% - 10px)
      min-height: 645px
      margin-bottom: 20px
      @media (max-width: 1200px)
        min-height: calc(50vw - 15px)
      @media (max-width: 800px)
        width: 100%
  .text
    background-color: clr-red
    color: clr-white
    padding: 6vw
    display: flex;
    flex-direction: column
    align-items: center;
    justify-content: center;
    h2
      font-size: 40px
      margin-top: 0
    p
      line-height: 125%
      letter-spacing: .5px
    .btn
      margin-top: 20px
  .image
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 500px
  .video
    min-height: 500px
    iframe
      width: 100% !important
      height: 100% !important
  .test-results
    .heading
      background-color: clr-red
      padding: 40px 15%
      color: clr-white
      h2
        font-size: 40px
        margin 0
    .results
      padding 30px 40px
      & + .results
        border-top: 1px solid gray
      h3
        font-size: 26px
        margin: 0 0 10px
      ul
        list-style: none;
        margin: 0
        padding: 0
      li
        display: flex;
        align-items: center;
        padding: 15px 0
        @media (max-width: 1000px) 
          flex-wrap: wrap;
        @media (max-width: 800px) 
          flex-wrap: nowrap;
        @media (max-width: 500px) 
          flex-wrap: wrap;
      .feature-image
        width: 15%
        @media (max-width: 1000px) 
          width: 30%
        @media (max-width: 800px) 
          width: 15%
        @media (max-width: 500px) 
          width: 30%
        img
          width: 100%
      .feature-title
        width: 35%
        text-align: center;
        padding: 0 20px
        @media (max-width: 1000px) 
          width: 70%
        @media (max-width: 800px) 
          width: 35%
        @media (max-width: 500px) 
          width: 70%
        h4
          color: clr-red
          font-size: 24px
          margin: 0
      .feature-text
        width: 50%;
        @media (max-width: 1000px) 
          width: 100%
        @media (max-width: 800px) 
          width: 50%
        @media (max-width: 500px) 
          width: 100%
        p
          margin: 0