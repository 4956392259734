#solutions {
  .hero {
    background-size: cover;
    color: clr-white;
    .wrapper {
      padding: 130px 0 80px 150px;
      @media (max-width: 1200px) {
        padding: 110px 10px 80px 10px;
      }
      h1 {
        font-size: 92px;
        letter-spacing: 4.5px;
        margin-bottom: 38px;
        @media (max-width: 1200px) {
          font-size: 50px;
        }
        +respond-on(600px) {
          line-height: 118%;
          letter-spacing: 1.9px;
          margin-bottom: 26px;
          font-size: 40px;
        }
      }
      .sub-heading {
        font-size: 22px;
        letter-spacing: 1px;
        font-weight: 400;
        margin-bottom: 40px;
        max-width: 70%;
        @media (max-width: 1200px) {
          font-size: 18px;
          letter-spacing: 0;
          max-width: 100%;
        }
        +respond-on(600px) {
          font-size: 16px;
          margin-bottom: 48px;
          max-width: 100%;
        }
      }
    }
  }

  .breadcrumbs {
    color: clr-lighter-gray;
    padding: 12px 0;
    @media (max-width: 1200px) {
      font-size: 12px;
      .head {
        letter-spacing: .5px;
      }
    }
    .selected {
      color: clr-gray;
    }
  }
}
