/**
 * General
 */

// hide onsale text
.onsale
    display: none

.product-single
    padding: 200px 0 100px 0
    background-color: clr-light-gray


.product-gallery-description
  display: flex;
  @media (max-width: 1090px)
    flex-wrap: wrap;
    justify-content: center;

/**
 * thumbnail/gallery side
 */

.product-gallery
  top: 0;
  left: 0;
  flex:  0 0 30%
  vertical-align: top;
  position: relative;
  width: 30%;
  max-width: 30%;
  @media (max-width: 1000px)
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 50px;
  @media (max-width: 800px)
    flex: 0 0 450px;
    width: inherit;
    max-width: 450px;
  @media (max-width: 500px)
    max-width: 100%;
    flex: 0 0 100%;
  .onsale
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: clr-red;
    color: clr-white;
    padding: 3px 8px;
    font-size: 14px;



.product-description
  display: block;
  width: 100%
  vertical-align: top
  padding-left: 40px
  @media (max-width: 1090px)
    padding-right: 20px
  @media (max-width: 1000px)
    display: block
    width: 100%
  @media (max-width: 800px)
    padding: 0

  .woocommerce-variation-description
    display: none;

.product-single-thumb
    margin-bottom: 20px
    img
        display: block
        width: 100%
        max-width: 100%
        height: auto

.product-single-gallery-list
    list-style: none
    margin: 0
    padding: 0

.product-single-gallery-list-item
    display: inline-block
    width: 31%
    margin-right: 3.5%
    margin-bottom: 3.5%

    &:nth-child(3n)
        margin-right: 0

/**
 * Text and pricing side
 */

.product-single-detail-header
    h2
        margin: 0 0 20px 0
        padding: 0
        color: clr-red
        font-size: 64px
        font-weight: 400

.product-single-detail-desc
    font-size: 20px

.product-single-detail-pricing
    .price
        color: clr-red
        font-size: 40px
        font-weight: 400
    .add-to-cart
        a
            text-decoration: none
            color: clr-gray
            padding: 15px 40px
            border: 2px solid clr-red
            transition: all 300ms ease


.woocommerce-variation-availability
    display: none

/**
 * WooCommerce Product Gallery
 */

.woocommerce-product-gallery
  .flex-control-thumbs
    padding: 0
    text-align: left
    li
      margin-bottom: 30px
      // display: flex
      // justify-content: center
      display: inline-block
      float: none
      text-align: center
      img  
        display: inline-block
      @media (max-width: 500px)
        margin-bottom: 15px
        max-width: 33.3333333%
        padding: 0 5px
        img
          max-width: 100%

.single_add_to_cart_button
    -webkit-appearance: none
    background: none
    outline: 0
    padding: 20px 40px
    text-transform: uppercase
    border: 2px solid clr-red
    cursor: pointer
    transition: all 300ms ease
    &:hover, &:active, &:focus
        background: clr-red
        color: clr-white



/**
 * Reviews
 */

.woocommerce-product-rating
    .star-rating
        margin-right: 20px

.woocommerce-review-link
    color: clr-red
    text-transform: uppercase
    font-family: 'Lato', sans-serif;
    font-size: 20px
    @media (max-width: 500px)
        font-size: 14px


/**
 * when review is active
 */

.product-gallery-description.review-active
    .product-gallery
        display: none
    .variations_form
        display: none
#tab-description
    display: block !important
    &.review-active
        display: none !important
#tab-reviews
    display: none !important
    &.review-active
        display: block !important


/**
 * Description
 */

#tab-description
    font-family: 'Lato', sans-serif;
    font-size: 20px
    line-height: 1.2


/**
 * variation selection
 */

.variations_form
  display: flex;
  justify-content: space-between;
  @media (max-width: 1090px)
    flex-wrap: wrap;
    justify-content: flex-end;

  .pd-product-selection-filter-wrapper
    flex: 0 1 auto

  .single_variation_wrap
    flex: 0 1 auto
    padding-right: 10%
    padding-left: 20px
    @media (max-width: 1090px)
      padding: 40px 0 0 20px



  .variations
    margin-bottom: 3px
    border-spacing: 10px
    @media (max-width: 1000px)
      padding-left: 0

  // variation selection labels
  td.label
    font-family: 'Oswald', sans-serif;
    font-size: 23px
    text-transform: uppercase
    color: clr-gray
    text-align: right
    @media (max-width: 500px)
        font-size: 14px

  .pd-quantity-select-wrapper
    text-align: right

  .pd-quantity-select-label
    display: inline-block
    font-family: 'Oswald', sans-serif;
    font-size: 23px
    text-transform: uppercase
    color: clr-gray
    text-align: right
    vertical-align: middle
    margin-right: 8px
    @media (max-width: 500px)
        font-size: 14px

  .pd-quantity-select-value
    display: inline-block
    vertical-align: middle
    margin-right: 12px
    text-align: left

  // hide extra single blog
  // > .woocommerce-variation.single_variation
      // display: none !important

  // selects
  .selectric-wrapper
    width: 160px
    border: 1px solid clr-gray


/**
 * WooCommerce single variation price and add to cart
 */

.single_variation_wrap

  // variable product price
  .woocommerce-variation-price
    display: block !important
    .price
      font-size: 36px
      display: block;
      color: clr-red
      margin-bottom: 30px
      del
        color: clr-gray
        display: block
        margin-bottom: 15px
        font-size: 28px
        .woocommerce-Price-amount
            opacity: 0.6 !important




// reset selection in variable product
.reset_variations
    display: none !important // design said no, but functionally, we might need this. So leave it there.


/**
 * Don't display unnecessary information
 */

#tab-title-additional_information
    display: none

#tab-title-description
    display: none

// .woocommerce-variation-add-to-cart
//     .quantity
//         display: none




/**
 * Variation specs for each product
 */

#product-spec-table-wrapper
    margin: 30px auto;


/**
 * Variation Specs Table
 */

.variation-specs-table
    border: 1px solid clr-light-gray
    border-collapse: collapse
    margin: 0
    padding: 0
    width: calc(100% - 40px)
    table-layout: fixed
    @media screen and (max-width: 940px)
        border: 0
        width: 100%

    thead
        @media screen and (max-width: 940px)
            border: none
            clip: rect(0 0 0 0)
            height: 1px
            margin: -1px
            overflow: hidden
            padding: 0
            position: absolute
            width: 1px
        tr
            background-color: clr-red
            color: clr-white

        th
            border-right: 2px solid clr-light-gray
            &:first-child
                border-left: 2px solid clr-red
            &:last-child
                border-right: 2px solid clr-red

    tr
        background-color: clr-light-gray
        padding: .35em
        @media screen and (max-width: 940px)
            display: block
            margin-bottom: .625em

    th, td
        padding: .625em
        text-align: center

    th
        font-family: 'Lato', sans-serif
        font-size: 14px
        text-transform: none

    td
        border: 2px solid clr-gray
        font-family: 'Lato', sans-serif
        font-weight: 300
        font-size: 14px
        &:before
            content: attr(data-label)
            float: left
            font-weight: bold
            text-transform: uppercase
            display: none
            @media screen and (max-width: 940px)
                display: block
        @media screen and (max-width: 940px)
            display: block
            font-size: .8em
            text-align: right
            border-bottom: 0
            min-height: 34px
        &:last-child
            @media screen and (max-width: 940px)
                border-bottom: 2px solid clr-gray
